@mixin sw-label ($bg: $brand-primary, $color: #fff, $border: null) {
    display: inline-block;
    vertical-align: middle;
    line-height: 1.8;
    margin-right: .5rem;
    padding: 0 .66em;

    border-radius: 3px;

    color: #fff;
    font-family: $font-family-sans-serif;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;

    &--light {
        font-family: 'Helvetica Neue', Helvetica;
        font-weight: 300;
        letter-spacing: 1px;
    }

    @if ($bg)       { @include sw-label-bg($bg); }
    @if ($color)    { @include sw-label-color($color, $border); }
}
@mixin sw-label--inactive () {
    opacity: .5;
}
@mixin sw-label-bg ($bg) {
    background: $bg;
}
@mixin sw-label-color ($color, $border: true) {
    color: $color;
    @if ($border)   {
        border: 1px solid;
        border-color: $color;
    }
}

.sw-gradient-label, .sw-label, .sw-label-hollow {
    @include sw-label(null);
    &--inactive { @include sw-label--inactive(); }
    &--small { font-size: 10px; }
    &--medium { font-size: 12px; }
}

.sw-gradient-label {
    @include sw-label-bg($sw-gradient-primary);
    &--gold { @include sw-label-bg(linear-gradient(to right, #ffd323, #ff9629)); }
}
.sw-label {
    &--success  { @include sw-label-bg($brand-success); }
    &--warning  { @include sw-label-bg($brand-warning); }
    &--danger   { @include sw-label-bg($brand-danger); }
}
.sw-label-hollow {
    @include sw-label-color(inherit);
    &--success  { @include sw-label-color($brand-success); }
    &--warning  { @include sw-label-color($brand-warning); }
    &--danger   { @include sw-label-color($brand-danger); }
}
