.sw-bank-card {
    position: relative;
    min-width: 230px;

    &::before {
        display: block;
        padding-top: (53.98 / 85.60 * 100%);
        content: '';
    }

    &__card {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 2.5rem;

        border-radius: 5px;
        background: linear-gradient(135deg, hsla(0, 0%, 0%, 0.286), rgba(0, 0, 0, 0.66));
        box-shadow: 0 1rem 3rem rgba(0, 0, 0, .15);
    }
    &__header, &__content {
        flex-grow: 1;
        flex-basis: 0;
    }

    &__header {
        display: flex;
        justify-content: space-between;
    }

    &__chip {
        height: 2.5rem;
        width: 3.5rem;
        background-color: rgba(0, 0, 0, .25);
        border-radius: 5px;
    }
    &__logo {
        height: 2.5rem;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: right;
    }

    &__number, &__expire {
        color: rgba(255, 255, 255, .4);
        font-family: Monaco;
        font-size: 14px;
        text-shadow: 0 1px .125rem rgba(0, 0, 0, .25);
    }
    &__number {
        letter-spacing: 1px;
    }
    &__expire-label {
        font-size: 12px;
        text-transform: uppercase;
    }
    &__number-last4, &__expire-date {
        color: #fff;
    }
}

.sw-mini-bank-card {
    display: inline-block;
    vertical-align: middle;
    padding: .2em .5em .2em .25em;
    line-height: 1;

    border-radius: .2em;
    background: linear-gradient(135deg, hsla(0, 0%, 0%, 0.286), rgba(0, 0, 0, 0.66));
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .1);

    &__logo {
        height: 1em;
    }
    &__number {
        color: #fff;
        font-family: Monaco;
        font-size: .75em;
        text-shadow: 0 1px .125rem rgba(0, 0, 0, .25);
    }
}
