/* Preload images */
body:after {
  content: url(../images/loading.gif);
  display: none;
}

body.lb-disable-scrolling {
  overflow: hidden;
}

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: $modal-backdrop-bg;
  @include opacity($modal-backdrop-opacity);
  display: none;
}

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal;

  .lb-image {
    display: block;
    height: auto;
    max-width: inherit;
    max-height: none;
    border-radius: $border-radius-small;

    /* Image border */
    border: 4px solid white;
  }

  a img {
    border: none;
  }
}

.lb-outerContainer {
  position: relative;
  *zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: $border-radius-base;

  /* Background color behind image.
     This is visible during transitions. */
  background-color: white;

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0;
}

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(../images/loading.gif) no-repeat;
}

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.lb-container > .nav {
  left: 0;
}

.lb-nav a {
  outline: none;
  background-image: url('data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==');
}

.lb-prev,
.lb-next {
  height: 100%;
  cursor: pointer;
  display: block;
}

.lb-nav {
  a.lb-prev,
  a.lb-next {
    @include opacity(0);
    @include transition-property(opacity);
    @include transition-duration(0.6s);

    &::before {
      position: absolute;
      font-size: 42px;
      top: 50%;
      @include translate(0,-50%);
    }

    &:hover {
      @include opacity(1);
    }
  }

  a.lb-prev {
    width: 34%;
    left: 0;
    float: left;

    @include make-sicon( $sicon-arrow-left-12 );

    &::before {
      left: $grid-gutter-width;
    }
  }
  a.lb-next {
    width: 64%;
    right: 0;
    float: right;

    @include make-sicon( $sicon-arrow-right-12 );

    &::before {
      right: $grid-gutter-width;
    }
  }
}

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  border-radius: $border-radius-base;
  position: absolute;
  top: -32px;
  left: 50%;
  transform: translateX(-50%);
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.lb-data {
  padding: 0 4px;
  color: #ccc;

  .lb-details {
    width: 85%;
    float: left;
    text-align: left;
    line-height: 1.1em;
  }

  .lb-caption {
    font-size: 13px;
    font-weight: bold;
    line-height: 1em;

    a {
      color: #4ae;
    }
  }

  .lb-number {
    display: block;
    clear: left;
    padding-bottom: 1em;
    font-size: 12px;
    color: #999999;
  }

  .lb-close {
    @extend .close;
    // display: block;
    // float: right;
    // width: 30px;
    // height: 30px;
    // background: url(../images/close.png) top right no-repeat;
    // text-align: right;
    // outline: none;
    // filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
    // opacity: 0.7;
    // -webkit-transition: opacity 0.2s;
    // -moz-transition: opacity 0.2s;
    // -o-transition: opacity 0.2s;
    // transition: opacity 0.2s;
    // &::after {
    //   content: $sicon-close;
    // }
    // @include opacity(0.7);
    // @include transition-property(opacity);
    // @include transition-duration(0.2s);
    @include make-sicon( $sicon-close );

    // &:hover {
    //   cursor: pointer;
    //   @include opacity(1);
    // }
  }
}
