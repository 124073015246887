// Grid system
.main {
  @include make-sm-column($grid-columns);
}

.col-sm-third { @include make-percent-sm-column(percentage(1/3)); }
.col-md-third { @include make-percent-md-column(percentage(1/3)); }

.col-sm-quarter { @include make-percent-sm-column(percentage(1/4)); }
.col-md-quarter { @include make-percent-md-column(percentage(1/4)); }

.row {
  @include make-row;
}

.row-no-padding {
  [class*="col-"] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@include make-grid-columns;

@media screen and (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
  .sidebar {
    display: block;
    @include make-responsive-col-gutter-width();
  }
}

@media screen and (min-width: $screen-md-max) {
  .main {
    @include make-sm-column($main-sm-columns);
    .sidebar-primary & {
      @include make-sm-column($main-sm-columns - ($sidebar-sm-columns + 1));
      @include make-sm-column-push($sidebar-sm-columns + 1);
    }
  }
  .sidebar {
    display: block;
    @include make-sm-column($sidebar-sm-columns);
  }
}
