.sw-gold-badge {
    padding: .1em .33em;

    border-radius: 3px;
    background: linear-gradient(to top, #ffd323, #ff9629);

    color: white;
    font-size: 16px;
    font-weight: bold;
    text-shadow: 0 .0625rem .0625rem rgba(0, 0, 0, .2);
}
