.invitations {
    &__header {
        margin-bottom: 2rem;
    }
    &__text-gold {
        color: #ffa827;
    }
    &__badge {
        font-size: 22px;
    }
    &__description {
        padding: 2rem;
        color: #999;
    }

    &__code-card-wrapper {
        max-width: 250px;
        margin: 2rem auto;
    }
    &__email {
        width:250px;
    }
}
