//
// Pager pagination
// --------------------------------------------------

.event-pager,
.profile-pager {
  @include make-row;
  width: 90px;
  float: right;
  margin-top: 5px;
  .pager {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.pager {
//   padding-left: 0;
//   margin: $line-height-computed 0;
//   list-style: none;
//   text-align: center;
//   @include clearfix;

    li {
      display: inline;
      > a,
      > span {
//       display: inline-block;

      padding: 2px;
      // AUGMENTER LA TAILLE ICI
      font-size: 1.9rem;
      // background-color: $pager-bg;
//       border: 1px solid $pager-border;
//       border-radius: $pager-border-radius;
      }
      .sicon {
        
        width: 20px;
        display: inline-block;
        padding-top: 5px;
      }
//
//     > a:hover,
//     > a:focus {
//       text-decoration: none;
//       background-color: $pager-hover-bg;
//     }
    }
    .vertical-center {
      margin-top: 5px;
    }
//
//   .next {
//     > a,
//     > span {
//       float: right;
//     }
//   }
//
//   .previous {
//     > a,
//     > span {
//       float: left;
//     }
//   }
//
//   .disabled {
//     > a,
//     > a:hover,
//     > a:focus,
//     > span {
//       color: $pager-disabled-color;
//       background-color: $pager-bg;
//       cursor: $cursor-disabled;
//     }
//   }
}

.pager-right,
.pager-left {
  padding-left: $grid-gutter-width / 2;
  padding-right: $grid-gutter-width / 2;
}

.pager-right {
  float: right;
}

.pager-left {
  float: left;
}

.pager-messages {
  margin : 0px !important;
  li > a {
    display: inline-block;
    padding: 2px !important;    
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 7px;
  }

}
