//
// List groups
// --------------------------------------------------


// Base class
//
// Easily usable on <ul>, <ol>, or <div>.

.list-group.list-profiles {
//   // No need to set list-style: none; since .list-group-item is block level
//   margin-bottom: 20px;
//   padding-left: 0; // reset padding because ul and ol
  border: $list-group-border-size solid $list-group-border-color;
  background-color: $list-group-bg;
}


// Individual list items
//
// Use on `li`s or `div`s within the `.list-group` parent.

.list-group-item.list-profiles {
  // position: relative;
  // display: block;
  padding: $list-group-padding;
  // Place the border on the list items and negative margin up for better styling
  margin-bottom: 0;
  // background-color: $list-group-bg;
  border: none;
  border-bottom: $list-group-item-border-size solid $list-group-item-border-color;

  // Round the first and last items
  // &:first-child {
  //   @include border-top-radius($list-group-border-radius);
  // }
  &:last-child {
    // margin-bottom: 0;
    // @include border-bottom-radius($list-group-border-radius);
    border: none;
  }
}


// Interactive list items
//
// Use anchor or button elements instead of `li`s or `div`s to create interactive items.
// Includes an extra `.active` modifier class for showing selected items.

// a.list-group-item,
// button.list-group-item {
//   color: $list-group-link-color;
//
//   .list-group-item-heading {
//     color: $list-group-link-heading-color;
//   }
//
//   // Hover state
//   &:hover,
//   &:focus {
//     text-decoration: none;
//     color: $list-group-link-hover-color;
//     background-color: $list-group-hover-bg;
//   }
// }

// button.list-group-item {
//   width: 100%;
//   text-align: left;
// }

// .list-group-item {
//   // Disabled state
//   &.disabled,
//   &.disabled:hover,
//   &.disabled:focus {
//     background-color: $list-group-disabled-bg;
//     color: $list-group-disabled-color;
//     cursor: $cursor-disabled;
//
//     // Force color to inherit for custom content
//     .list-group-item-heading {
//       color: inherit;
//     }
//     .list-group-item-text {
//       color: $list-group-disabled-text-color;
//     }
//   }
//
//   // Active class on item itself, not parent
//   &.active,
//   &.active:hover,
//   &.active:focus {
//     z-index: 2; // Place active items above their siblings for proper border styling
//     color: $list-group-active-color;
//     background-color: $list-group-active-bg;
//     border-color: $list-group-active-border;
//
//     // Force color to inherit for custom content
//     .list-group-item-heading,
//     .list-group-item-heading > small,
//     .list-group-item-heading > .small {
//       color: inherit;
//     }
//     .list-group-item-text {
//       color: $list-group-active-text-color;
//     }
//   }
// }


// Contextual variants
//
// Add modifier classes to change text and background color on individual items.
// Organizationally, this must come after the `:hover` states.

// @include list-group-item-variant(success, $state-success-bg, $state-success-text);
// @include list-group-item-variant(info, $state-info-bg, $state-info-text);
// @include list-group-item-variant(warning, $state-warning-bg, $state-warning-text);
@include list-group-item-variant(danger, $list-group-item-primary-bg, $list-group-item-primary-color);


// Custom content options
//
// Extra classes for creating well-formatted content within `.list-group-item`s.

// .list-group-item-heading {
//   margin-top: 0;
//   margin-bottom: 5px;
// }
// .list-group-item-text {
//   margin-bottom: 0;
//   line-height: 1.3;
// }

// Swipe list-messages
//

.list-group {
  &.swipe-left,
  &.swipe-right {
    .behind {
      @include size(75%, 100%);
      position: absolute;
      top: 0;
      display: flex;
      flex-direction: row;

      @media (min-width: $screen-md-min) {
        @include size(30%, 100%);
      }
      .btn {
        flex-basis: 100%;
        border-radius: 0;
        margin: 0px;
      }
    }
  }

  &.swipe-left {
    .behind {
      right: 0;
    }

    .sweeping-tile {
      @include transition(margin-right .5s);
    }

    .list-group-item {
      &:hover,
      &:focus {
        .sweeping-tile {
          @media (max-width: $screen-sm-max) {
            margin-right: 75%;
            overflow: hidden;
            height: 61px;
            white-space: nowrap;
          }
          @media (min-width: $screen-md-min) {
            margin-right: 30%;
          }
        }
      }
    }
  }
  &.swipe-right {
    .behind {
      left: 0;
    }

    .sweeping-tile {
      @include transition(margin-left .5s);
    }

    .list-group-item {
      &:hover,
      &:focus {
        .sweeping-tile {
          margin-left: 30%;
        }
      }
    }
  }
}

.modal {
  .list-group {
    &.swipe-left,
    &.swipe-right {
      .behind {
        @include size(50%, 100%);
      }
    }

    &.swipe-left {
      .list-group-item {
        &:hover,
        &:focus {
          .sweeping-tile {
            margin-right: 50%;
          }
        }
      }
    }
    &.swipe-right {
      .list-group-item {
        &:hover,
        &:focus {
          .sweeping-tile {
            margin-left: 50%;
          }
        }
      }
    }
  }
}

// List Group Profiles

.list-group-profiles {
  background-color: $list-group-bg;
  border: 2px solid $list-group-border-color;
  min-height: $list-group-profiles-min-height;

  .list-group-item {
    padding: 0;
    border-color: $list-group-item-border-color;
    border-left: none;
    border-right: none;

    &:first-child {
      border-top: none;
    }

    &:hover,
    &:focus {
      .profile-entry {
        background-color: lighten($list-group-active-bg, 37%);
      }
    }
  }

  .profile-entry {
    display: block;
    position: relative;
    color: $gray;
    background-color: $list-group-bg;
    margin-left: 0;
    padding: 10px 15px 10px 35px;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .profile-picture {
    @include size(20px, 20px);
    position: absolute;
    left: 10px;
    top: 10px;
    border-width: 1px;
  }

  .profile-name {
    color: $gray-dark;
  }

  .profile-screenname {
    color: $gray-lite;
  }

  .profile-location {
    float: right;
  }
}

.modal {
  .list-group-profiles {
    min-height: inherit;
    height: $list-group-profiles-min-height / 2;
    overflow-y: scroll;
  }
}

.form-horizontal {
  .list-group-profiles {
    margin-bottom: 0;
  }
}
