@media screen and (min-width: $screen-md-max) {
  
}

// 3 column layout for the home content
@media (min-width: $screen-md-min) {
  .col-md-33 {
    width: 33%;
  }
}

@media (min-width: $screen-lg-min) {
  .col-lg-25 {
    width: 25%;
  }
}



// small lips
.swingsy-lips {
  max-width: 44px;
  height: auto;
}

.col-centered {
  margin-left: auto;
  margin-right: auto;
}

// Placeholder for editable divs
[contentEditable=true]:empty:not(:focus):before {
  content:attr(data-text)
}

// Force pointer cursor on ngClick links
a {
  &[ng-click],
  &[data-ng-click],
  &[x-ng-click] {
      cursor: pointer;
  }
}

.js-basic-single {
  width: 100% !important;
}

.blurred{
  -webkit-filter: blur(10px);
  filter: blur(10px);
}

.lg-backdrop {
  background-color: rgba(0, 0, 0, 0.80) !important;
}
