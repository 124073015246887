.home {
  #main-navbar {
    margin-bottom: 0;

    .home-navbar-brand{
    display: block;
    margin: 40px 0 0 30px;
    @include img-retina("../images/home/swingsy-logo.png", "../images/home/swingsy-logo.png", 370px, 240px);
    background-repeat: no-repeat;
    background-position: left center;
    width: 364px;
    height: 163px;
    background-size: contain !important;
    transition: width 0.5s, height 0.5s;
    @include text-hide();
    }

    .home-navbar-brand.small{
      width: 210px;
      height: 93px;
      margin: 20px 0 0 30px;
    }

    #connectionBox{
      margin: 100px 30px 0 0;
      transition: margin-top 0.5s;

      #loginButton{
        border: 2px solid #ee5767;
        border-radius: 25px;
        padding: 15px 15px;
        color: #FFF;
        text-transform: uppercase;
        font-size: 22px;
      }
      
      .home-header__select-lang{
        border: 2px solid #ee5767;
        border-radius: 25px;
        padding: 14px 15px;
        margin-left: 10px;
      }
    }
    #connectionBox.small{
      margin: 38px 30px 0 0;
    }
    
    @media (max-width: $screen-sm-min) {

      li {
        float: left;
      }

      .footerLogo{
        display: none;
      }
  
      .home-navbar-brand{
        margin: 20px 0 0 10px;
        width: 32vw;
        height: 15vw;
        background-size: 32vw 15vw;
      }
  
      #connectionBox{
        margin: 25px 10px 0 0;
  
        #loginButton{
          padding: 5px 10px;
          font-size: 3vw;
          border-radius: 15px;
        }

        .home-header__select-lang{
          border-radius: 15px;
          padding: 5px 5px;
          margin-left: 5px;
          width:61px;
        }

      }
    }
  }

  @media (max-width: $screen-sm-min) {
  
    #main-navbar{
      background: linear-gradient(rgba(0,0,0,1), rgba(0,0,0,0.7));
    }
  }


  #main-navbar.small{
    background: linear-gradient(rgba(0,0,0,1), rgba(0,0,0,0.5));
  }

  #mainView{
    margin-top:0;
    background-color: black;

    .part1{
      position: relative;
      padding:200px 0 200px 0;
      text-align: center;
      background-image: url("../images/home/homeBackground1-desktop.jpg");
      background-repeat: no-repeat;
      background-size: cover;

      @media (max-width: $screen-sm-min) {
        background-image: url("../images/home/homeBackground1-mobile.png");
        padding-top: 150px;
      }
      
      &::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-color: rgba(0,0,0,0.15);
      }

      .btn{
        position: relative;
        margin-top:20px;
        padding: 8px 45px;
        border-radius:33px;
        font-family: 'circular';
        font-size:38px;
        text-transform: uppercase;
        
        @media (max-width: $screen-sm-min) {
          font-size: 6vw;
        }

      }

      .home-hero__title{
        margin-top: 130px;

        @media (max-width: $screen-sm-min) {
          margin-top: 0;
        }
      }

     


    }

    .swingsyExp h3{
      position: relative;
      top: -100px;
      color: white;
      text-transform: uppercase;
      font-size: 45px;
      font-family: "fields-display", sans-serif;
      font-weight: 800;
  
      @media (max-width: $screen-sm-min) {
            font-size: 9vw;
      }
    }

    .ThreeSixtyDeg{
      padding: 50px 5% 50px;
      text-align: center;
      display: flex;
      flex-wrap: wrap;

      @media (max-width: $screen-sm-min) {
        display: block;
      }
    }

    .ThreeSixtyDegCart{
      background-color: #242424;
      border:2px solid #ee5767; 
      border-radius: 25px;
      padding: 25px 50px;
      color: #FFF;
      font-size: 25px;
      height: 100%;

      @media (max-width: $screen-sm-min) {
        margin-bottom: 15px;
      }

      h3{
        margin:35px 0;
        text-transform: uppercase;
        font-size: 38px;
        font-family: "fields-display", sans-serif;
        font-weight: 400;

        @media (max-width: $screen-sm-min) {
          font-size: 9vw;
        }
      }

      p{
        font-family:'GothamLight';
        font-size:22px;
        
        @media (max-width: $screen-sm-min) {
          font-size: 4.5vw;
        }
      }
    }

    .part5{
      position: relative;
      //@include img-retina("../images/home/homeBackground2.jpg", "../images/home/homeBackground2.jpg", 1900px, 1080px);
      background-image: url("../images/home/homeBackground2-desktop.jpg");
      background-repeat: no-repeat;
      background-size: cover;

      @media (max-width: $screen-sm-min) {
        background-image: url("../images/home/homeBackground2-mobile.png");
      }

      &::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-color: rgba(0,0,0,0.15);
        //background-color: rgba(152, 66, 211, 0.63);
      }
    }
    .part6{
      position: relative;
      @include img-retina("../images/home/triangle-disponible-stores.png", "../images/home/triangle-disponible-stores.png", 949px, 1165px);
      background-repeat: no-repeat;
      background-size: cover;

      #devices{
        padding: 50px 30px 60px 60px;
        width: 100%;
        @media (max-width: $screen-sm-min) {
          padding: 0px 20px;
        }

      }

      .onStores{
        padding:80px 0 0;
        color: #FFF;
        font-family: 'GothamLight';
        font-size: 25px;
        line-height: 30px;

        @media (max-width: $screen-sm-min) {
          padding:0 15px;
        }
      }

      .pitch{
        margin-top:45px;
        text-transform: uppercase;
        font-size: 1.8vw;
        color:#ee5767;
        text-align: left;

        @media (max-width: $screen-sm-min) {
          color:black;
          font-size: 5.8vw;
        }
      }

      p{
        text-align: left;
        margin:20px 0 60px;

        @media (max-width: $screen-sm-min) {
          font-size: 4.5vw;
        }
      }
    }

    .trustSite{
      padding: 0 25px;
      height: 100%;
      font-family: GothamLight;
      font-size: 22px;
      color: #FFF;
      line-height: 26px;
           
      .stats{
        width: 80%;
        //border:rgb(222, 227, 231) 1px dashed;

        @media (max-width: $screen-lg-min) {
          //border:rgb(80, 163, 230) 1px dashed;
          width: 60%;
        }
  
        @media (max-width: $screen-md-min) {
          //border:rgb(230, 80, 110) 1px dashed;
          width: 80%;
        }

        @media (max-width: $screen-sm-min) {
          //border:rgb(80, 230, 143) 1px dashed;
          width: 80%;
        }

        @media (max-width: $screen-sm-min) {
          //border:rgb(80, 230, 143) 1px dashed;
          width: 60%;
        }

        @media (max-width: $screen-xs-min) {
          //border:rgb(244, 202, 19) 1px dashed;
          width: 80%;
        }
      }

      
    }

    .redTitle{
      margin-top:45px;
      text-transform: uppercase;
      font-size: 2vw;
      color:#ee5767;
      text-align: left;
      @media (max-width: $screen-md-min) {
        font-size: 3vw;
      }
      @media (max-width: $screen-sm-min) {
        font-size: 4vw;
      }
      @media (max-width: $screen-xs-min) {
        font-size: 5vw;
      }
    }

    .part7{
      position: relative;
      background-image: url("../images/home/homeBackground3-desktop.jpg");
      background-repeat: no-repeat;
      background-size: cover;

      @media (max-width: $screen-sm-min) {
        display: none;
        background-image: url("../images/home/homeBackground3-mobile.png");
      }
      
      &::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-color: rgba(0,0,0,0.15);
        //background-color: rgba(152, 66, 211, 0.63);
      }

    }

    .part9{
      .plansBySituation{
        background-image: url("/public/dist/images/home/Rectangle.png") !important;
        /*margin: 10px;*/
        height: 203px;
        font-size:x-large;
        color: #FFF;
        background-size: cover;
        background-repeat: no-repeat;
      }
    
      .plansBySituation .price{
          font-family: "fields-display", sans-serif;
          font-weight: 400;
          font-size: 35px;

          @media (max-width: $screen-sm-min) {
            font-size: 5vw;
           }
      }
      
      #plans td{
          /*border: #ee5767 1px solid;*/
          padding: 0 10px;
      }
      
      #plans .title{
          font-size:x-large;
          color: #FFF;
          text-transform: uppercase;

          @media (max-width: $screen-sm-min) {
           font-size: 3vw;
          }
      }
      
      .duration{ 
        @media (max-width: $screen-sm-min) {
          font-size: 4vw;
         }
      }
    }
  }

  .pinkText{
    color:$brand-primary;
  }
}

.home-header {

  //margin-top: 56px;

  &__link {
    font-size: 16px;
  }

  &__select-lang {
    // Ensure the <select> inside the select2 component do not go out of the window
    overflow: hidden;

    .select2-selection {
      padding-left: 0;
      padding-top: 0;
      padding-bottom: 0;
      height: auto;
      border: none;
    }
  }
}

@mixin text-gradient() {
  display: inline-block;
  vertical-align: baseline;

  background: $sw-gradient-primary;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  // fallback color
  color: rgb(230, 80, 110);
}

.home-hero {
  position: relative;
  overflow: hidden;

  &__header {
    position: absolute;
    width: 100%;
    top:70px;
    z-index: 2;
    text-align: center;
  }
  
  &__title {
    position: relative;
    font-family: "fields-display", sans-serif;
    font-weight: 800;
    font-size: 90px;
    color: white;
  }

  &__subtitle {
    position: relative;
    font-family: "fields-display", sans-serif;
    font-weight: 400;
    font-size: 70px;
    color: white;
    padding: 5px 5% 10px;
  }

  &__text{
    position: relative;
    font-family: GothamLight;
    font-size: 24px;
    color: white;
    margin: 0 auto;
    padding: 50px 15% 70px;
  }

  &__punchline {
    position:absolute;
    text-align:center;
    color:white;
    font-style: italic;
    text-shadow: 4px 4px 2px rgba(0,0,0, 0.6);
    text-transform:uppercase;
  }

  &__credentials {
    position:absolute;
    bottom:5px;
    width:210px;
    color:#BBB;
    font-size:11px;
  }

  &__travelAd {
    position:absolute;
    bottom:85px;
    left:65px;
    width:225px;
    color:white;
    text-shadow: 1px 2px 2px #000;
    text-transform:uppercase;
    font-size:22px;
  }

  &__stores {
    position: relative;
    text-align: center;
    max-height: 10px;
    z-index: 999;
  }

  &__signup-login{
    display:none;
  }

  &__signup-button {
    font-size: 28px;
    text-transform:uppercase;
    text-shadow: none;
    font-style: normal;
    color:#FFF;
  }
  /*
  @media (min-width: $screen-sm-min) {
    &__title {
      font-size: 24px;
      letter-spacing: 0.020em;
    }

    &__punchline {
      top:170px;
      right:80px;
      width:350px;
      font-size:20px;
    }

    &__credentials {
      left:40px;
    }

    &__travelAd {
      font-size:16px;
    }

    &__stores {
      top: -18px;
    }
    

  }
  */
/*
  @media (min-width: $screen-md-min) {
    &__title {
      font-size: 28px;
      letter-spacing: 0.022em;
    }

    &__punchline {
    top:175px;
    right:80px;
    width:460px;
    font-size:28px;
    }

    &__credentials {
      left:70px;
    }
    
    &__travelAd {
      font-size:22px;
    }

    &__stores {
      top: -23px;
    }
    
  }
*/
/*
  @media (min-width: $screen-lg-min) {
    &__title {
      font-size: 35px;
      letter-spacing: 0.025em;
    }

    &__punchline {
      top:40%;
      right:10%;
      width:460px;
      font-size:40px;
    }
  }
*/

@media (max-width: $screen-md-min) {
  &__title {
    font-size: 11vw;
  }

  &__subtitle {
    font-size: 6vw;
  }

  &__text{
    font-size: 4.5vw;
    padding-left: 10%;
    padding-right: 10%;
  }
}

/*
  @media (max-width: $screen-xs-max) {
    &__title {
      font-size: 20px;
      letter-spacing: 0.020em;
      margin: 0 auto;
      width:260px
    }

    &__punchline {
      bottom: 5%;
      left: 50%;
      width: 250px;
      font-size: 13px;
      transform: translate(-50%, -50%);
    }

    &__signup-button, &__credentials{
      display: none;
    }

    &__signup-login{
      display:block;
      margin-top: 45px;
      font-size: 15px;
      text-transform:uppercase;
    }

    &__signup2-button{
      margin-right: 25px;
    }

    &__stores {
      top: 10px;

      img{
        width: 100px;
        margin:0 12px;
      }
    }

    &__travelAd {
      font-size:10px;
    }

  }
  */
  /*
  @media (max-width: 360px) {

    &__travelAd {
      display: none;
    }

    &__stores img{
        width: 80px;
        margin:0 5px;
    }
    
  }
  */
   
}

.home-subhero {
  background: $body-bg;
  padding: 0 3rem;

  &__more-box {
    position: relative;
    margin: -30px auto;
    padding: 2rem 2rem 2rem 2rem;
    max-width: 42rem;

    border-radius: 5px;
    background: #fff;
    box-shadow: 0 1rem 4rem rgba(0, 0, 0, 0.2);
  }

  &__col {
    display: flex;
    align-items: center;

    margin: auto;
    margin-bottom: 2rem;
    max-width: 32rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__icon {
    float: left;
    margin-right: 2rem;
    height: auto;
    width: 7rem;
  }

  &__description {
    font-family: 'Helvetica Neue', 'Helvetica';
    font-weight: 300;
    font-size: 16px;
    color: #666;
  }

  &__text-strong {
    @include text-gradient();
    font-family: $font-family-sans-serif;
    font-weight: 400;
  }

  @media (min-width: $screen-sm-min) {
    padding: 0 2rem;

    &__more-box {
      max-width: 86rem;
      padding: 1.5rem;
    }

    &__col {
      margin-bottom: 0;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    &__icon {
      margin-right: 1.5rem;
      height: auto;
      width: 6rem;
    }

    &__description {
      font-size: 14.5px;
    }
  }

  @media (min-width: $screen-md-min) {
    padding: 0 3rem;

    &__more-box {
      max-width: 92rem;
      padding: 2rem 3rem;
    }

    &__icon {
      height: 7rem;
      width: auto;
    }

    &__description {
      font-size: 16px;
    }
  }
}

.main-content {
  //padding-top: 70px;
  //padding-bottom: 90px;
  @extend .clearfix;
}

.home-content {
  
  margin-top: 25px;
  background: white;

  /* start fix for https://medium.com/wdstack/varying-column-heights-in-bootstrap-4e8dd5338643 

      could not use a bootstrap "col-md-10" or "col-lg-10" class for the "summary" div above without breaking the design, had to use simple div 
      with responsive margin sizes
  */
  .row.display-flex {
    display: flex;
    flex-wrap: wrap;
  }
  .row.display-flex > [class*='col-'] {
    display: flex;
    flex-direction: column;
  }
  /* end fix */

  h2 {
      font-size: $font-size-large;
      font-weight: 900;
      margin-bottom:0;
      padding-bottom:0
  }
  h3 {
      padding-bottom: 10px;
      border-bottom: 2px solid $brand-primary;
      font-weight: 600;
      font-size: 18px;
  }
  p {
    font-size: $font-size-medium;
  }

  @media (max-width: $screen-xs-max) {
    .summary {
     display:none;
    }

    h3 {
      font-size: $font-size-medium;
      padding-bottom: 5px;
      border-bottom: 1px solid $brand-primary;
      font-size: 16px;
      text-transform: uppercase;
    }
  }
  /*
  @media (min-width: $screen-sm-min) {
    .summary {
      margin:0 3px;
    }
  }
*/
  @media (min-width: $screen-md-min) {
    /*.summary {
      margin:0 7px;
     }
*/
    .col-md-33 {
      //padding: 15px;
      &:nth-child(3n+1) {
        padding-left: 7px !important;
      }
      &:nth-child(3n+3) {
        padding-right: 7px !important;
      }
    }
    
  }
  @media (min-width: $screen-lg-min) {
    /*
    .summary {
      margin:0 15px;
     }
*/
    .col-md-33 {
      //padding: 25px;
      &:nth-child(3n+1) {
        padding-left: 15px !important;
      }
      &:nth-child(3n+3) {
        padding-right: 15px !important;
      }
    }
    
  }

}

.home .wrap.container {
  position: inherit;
}

.main-footer {
  background-color: #000;
  padding: 0 30px 30px 30px;
  margin-top: 100px;

.footerLogo{
  width: 350px;
}

.internalLinks{
    list-style: none;
    margin-top: 90px;
    font-size: x-large;
  }

  .newsletterForm{
    text-align: right;
    /*padding-right: 15px;*/
    margin-bottom: 40px;

    .title {
      color: #fff;
      font-size: x-large;
      margin-bottom: 20px;
    }
  }
  
  .socialLinks {
    text-align: right;
    
    .title {
      color: #fff;
      font-size: xx-large;
      margin-bottom: 30px;
    }
    
    a {
      color: #fff;
      text-decoration: none;
      &:hover {
        color: #fff;
      }
    }
  }

  .links-log,
  .links-social,
  .links-footer,
  .links-partner {
    padding-left: 0;
  }
  .links-footer,
  .links-social {
    li {
      display: inline-block;
      margin-right: 5rem;
    }

    a {
      color: #fff;
      text-decoration: none;
      &:hover {
        color: #fff;
      }
    }
  }
   
  .logo {
    width: 45px;
    margin-right: 20px;
  }

  @media (max-width: $screen-sm-min) {
  
   
    .footerLogo{
      display: none;
    }

    .home-navbar-brand{
      margin: 20px 0 0 10px;
      width: 32vw;
      height: 15vw;
    }

    #connectionBox{
      margin: 20px 10px 0 0;

      #loginButton{
        padding: 5px 10px;
        font-size: 3vw;
      }
    }
  }
  
}
