.coupsdecoeur-pastille {
  font-size: $font-size-large;
  padding: 10px;
  position: absolute;
  top: 0px;
  right: $grid-gutter-width / 2;
  z-index: 10;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}
