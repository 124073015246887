.card-event {
  @extend .panel-event;
  min-height: 211px;

  .card-heading {
    min-height: inherit;
    padding-left: 6px;
    padding-right: 6px;
    /*padding-top : 0;*/
  }

  .event-cover {
    width: 100%;

    > img {
      @include img-responsive;
    }
  }

  .event-cal {
    font-size: 11px;
    @include size(34px, 34px);

    .day {
      display: block;
      font-size: $font-size-small;
      line-height: 11px;
      margin-top: 2px;
    }
  }

  .event-title {
    font-size: $font-size-medium;
    margin-bottom: 0;
  }

  .event-subtitle {
    clear: none;
    font-size: $font-size-small;
    margin-bottom: 0;
  }

  .event-content {
    width: 100%;
  }

  table {
    margin-bottom: 0;

    > tbody {
      color: $gray;

      > tr {
        > th {
          font-size: 14px;
        }
        > th,
        > td {
          padding: 4px 6px;
          line-height: 24px;
          height: 33px;
        }
      }
    }
  }
}

.list-invitations {
  color: $gray;
}

.invitation-entry {
  border-top: 1px solid $gray-light;
  border-bottom: 1px solid $gray-light;
  padding-top: $line-height-computed;
  margin-top: -1px;
}

.card-invitation {
  color: $gray;
  text-align: center;
  position: relative;
  padding-top: 100px;

  .sicon {
    font-size: 32px;
    display: block;
    margin: 0 auto ($line-height-computed / 2) auto;
  }
}


@media screen and (max-width: $screen-sm-max) {
  .admin-btns {
    text-align: center;
    .btn {
      width: 100%;
      margin-bottom: .5rem;
    }
  }
}

@media screen and (min-width: $screen-sm-max) {
  .card-event {
    .event-cover {
      width: 33%;
    }

    .event-content {
      width: 67%;
    }
  }
}
