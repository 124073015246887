// Basic buttons overrides
// --------------------------------------------------

.btn {
    
  border-radius: 7px;
  
  &[disabled] {
    cursor: default;
  }
}

.btn-primary {
  background: $brand-primary;
  box-shadow: 0 .125em .5em rgba(0,0,0,.2);

  &:hover {background: darken($brand-primary, 15%)}//{ background: linear-gradient(90deg, lighten($sw-gradient-primary-from, 5%), lighten($sw-gradient-primary-to, 5%)); }
  &:active {background: green}//{ background: linear-gradient(90deg, darken($sw-gradient-primary-from, 15%), darken($sw-gradient-primary-to, 15%)); }

  &[disabled] {
    &, &:hover, &:active {
      background: $gray-lite;
    }
  }
}

.btn-outline-primary {
  &:hover {
    background: $sw-gradient-primary;
    box-shadow: 0 .125em .5em rgba(0,0,0,.15);
  }
  &.active, &:active, &:focus {
    box-shadow: 0 .25em .5em rgba(0,0,0,.2) inset;
  }
  &.active {
    background: linear-gradient(90deg, lighten($sw-gradient-primary-from, 5%), lighten($sw-gradient-primary-to, 5%));
    &:hover, &:active, &:focus {
      background: $sw-gradient-primary;
    }
  }
}

// Outline buttons
// --------------------------------------------------

.btn-outline-default {
  @include button-outline-variant($btn-outline-default-color);
}
.btn-outline-primary {
  @include button-outline-variant($btn-outline-primary-color);
}
.btn-outline-white {
  @include button-outline-variant(white);
}
// Success appears as green
.btn-outline-success {
  @include button-outline-variant($btn-outline-success-color);
}
// Info appears as blue-green
.btn-outline-info {
  @include button-outline-variant($btn-outline-info-color);
}
// Warning appears as orange
.btn-outline-warning {
  @include button-outline-variant($btn-outline-warning-color);
}
// Danger and error appear as red
.btn-outline-danger {
  @include button-outline-variant($btn-outline-danger-color);
}



// Underline buttons
// --------------------------------------------------

.btn-underline-default {
  @include button-underline-variant($btn-underline-default-color, $btn-underline-default-border-color, $btn-underline-default-active-color);
}
.btn-underline-primary {
  @include button-underline-variant($btn-underline-primary-color, $btn-underline-primary-border-color, $btn-underline-primary-active-color);
}
// Success appears as green
.btn-underline-success {
  @include button-underline-variant($btn-underline-success-color, $btn-underline-success-border-color, $btn-underline-success-active-color);
}
// Info appears as blue-green
.btn-underline-info {
  @include button-underline-variant($btn-underline-info-color, $btn-underline-info-border-color, $btn-underline-info-active-color);
}
// Warning appears as orange
.btn-underline-warning {
  @include button-underline-variant($btn-underline-warning-color, $btn-underline-warning-border-color, $btn-underline-warning-active-color);
}
// Danger and error appear as red
.btn-underline-danger {
  @include button-underline-variant($btn-underline-danger-color, $btn-underline-danger-border-color, $btn-underline-danger-active-color);
}



// Underline buttons
// --------------------------------------------------

// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-action {
  @extend .btn-link;
  color: $gray;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}


// Link sizes
// -------------------------
.btn-large {
  padding: 24px 30px;
  font-size: $font-size-medium;
  @media (min-width: $screen-lg-min) {
    font-size: $font-size-large;
  }
}


// Outline on Focus

a,
button {
  &:focus {
    outline: none;
  }
}
