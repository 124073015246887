.label-outline-default {
  @include label-outline-variant($label-default-bg);
}

.label-outline-primary {
  @include label-outline-variant($label-primary-bg);
}

.label-outline-success {
  @include label-outline-variant($label-success-bg);
}

.label-outline-info {
  @include label-outline-variant($label-info-bg);
}

.label-outline-warning {
  @include label-outline-variant($label-warning-bg);
}

.label-outline-danger {
  @include label-outline-variant($label-danger-bg);
}
