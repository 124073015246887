.panel-event {
  @include clearfix;

  .event-cal {
    color: $brand-primary;
    text-align: center;
    @include size(50px, 50px);
    border: 2px solid $brand-primary;
    border-radius: $border-radius-base;
    float: left;
    margin-right: $grid-gutter-width / 2;
    margin-bottom: $grid-gutter-width / 2;
    /*margin-bottom:1px;*/

    .day {
      display: block;
      font-size: 21px;
      line-height: 21px;
      margin-top: 3px;
    }
  }

  .event-cover {
    position: relative;
    background-color: $gray-light;
    > img {
      display: block;
      width: 453px;
      height: 679px;
      @include img-responsive();
    }

    .edit-image {
      position: absolute;
      bottom: 15px;
      right: 20px;
    }
  }

  .event-title {
    font-size: 28px;
    margin-top: 0;
    min-height: 40px;
  }

  .event-subtitle {
    clear: both;
    color: $gray;
  }

  .event-labels {
    white-space: nowrap;
  }

  .event-link {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .event-col-short {
    width: 45px;
  }

  table {
    table-layout: fixed;

    > tbody {
      > tr {
        line-height: $font-size-medium;
        > th {
          width: 45px;
          padding-right: 0;
          text-align: center;
          color: $brand-primary;
          font-size: $font-size-medium;
        }

        &.event-datetime,
        &.event-address {
          color: $gray;

          strong {
            color: $gray-dark;
            display: block;
          }
        }
      }
    }
  }

  .event-stats {
    border-left: none;
    border-right: none;
    > tbody {
      > tr {
        > td {
          text-align: center;
          font-size: $font-size-small;
          border-style: solid;

          @media screen and (max-width: $screen-xs-max) {
            padding: 5px;
          }

          strong {
            color: $brand-primary;
            font-size: $font-size-medium;
            font-weight: normal;
            display: block;
          }

          &:first-child {
            border-left: none;
          }
          &:last-child {
            border-right: none;
          }
        }
      }
    }
  }

  .more,
  .more > a {
    font-size: $font-size-large;
  }

  .panel-body {
    p {
      color: $gray;
    }

    .btn-group {
      .btn {
        margin-top: 10px;
        float: left;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}

.panel-event-album {
  .list-photos {
    margin: -10px;
    // @include clearfix;
    > li {
      width: 25%;
      padding: 10px;
      padding: 10px;
    }
  }
}

.panel-lieu {
  color: $gray;

  .wrapper {
    position: relative;
  }

  .venue-cover {
    background-color: $gray-light;
  }

  .venue-name {
    color: $gray-dark;
    margin-top: 0;
  }

  .venue-summary {
    white-space: pre-line;
  }

  .venue-icon {
    padding-right: .66em;
    text-align: right;
  }
}

.panel-participants {
  .panel-heading {
    .badge {
      background-color: $brand-primary;
    }
  }
  .panel-body {
    height: 190px;
    overflow-y: scroll;
    text-align: center;
    color: $gray;
  }
  .participants-msg {
    @include make-vertical-align;
  }
  .list-participants {
    @include list-unstyled;
    margin: $grid-gutter-width / 4;

    > li {
      float: left;
      width: 25%;
      position: relative;
      padding: $grid-gutter-width / 4;

      > a > img {
        @include img-responsive;
      }
    }
  }
}

.row.list-unstyled {
  li {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }
}

.panel-organisateur {
  .profile-wrapper {
    float: left;
    width: 75%;
  }
}

#modal-cover {
  .event-album {
    text-align: center;
    img {
      max-width: 100%;
    }
  }
}
#modal-participants {
  .participants-search-results {
     .invite-searchfield {
      margin-bottom: 15px;
    }
    .list-group {
      //border-top: none;
    }
  }
}

.event-album {
  list-style: none;
  padding: 0;
  li {
    width: 50%;
    display: inline-block;
    margin-bottom: 15px;
  }
}

@media screen and (min-width: $screen-sm-max) {
  .panel-event {
    .event-cover,
    .event-content {
      float: left;
      width: 50%;
    }

    .panel-body {
      .btn-group {
        .btn {
          margin-top: 0;
        }
      }
    }
  }
}
