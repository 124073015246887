// Navbar nav links
//
// Builds on top of the `.nav` components with its own modifier class to make
// the nav the full height of the horizontal nav (above 768px).

.navbar-nav {
  > li {
    margin-left: 2px;
    margin-right: 2px;
  }
  // margin: ($navbar-padding-vertical / 2) (-$navbar-padding-horizontal);
  //
  // > li > a {
  //   padding-top:    10px;
  //   padding-bottom: 10px;
  //   line-height: $line-height-computed;
  // }
  //
  // @media (max-width: $grid-float-breakpoint-max) {
  //   // Dropdowns get custom display when collapsed
  //   .open .dropdown-menu {
  //     position: static;
  //     float: none;
  //     width: auto;
  //     margin-top: 0;
  //     background-color: transparent;
  //     border: 0;
  //     box-shadow: none;
  //     > li > a,
  //     .dropdown-header {
  //       padding: 5px 15px 5px 25px;
  //     }
  //     > li > a {
  //       line-height: $line-height-computed;
  //       &:hover,
  //       &:focus {
  //         background-image: none;
  //       }
  //     }
  //   }
  // }

  // Uncollapse the nav
  @media (min-width: $grid-float-breakpoint) {
    // float: left;
    // margin: 0;

    > li {
      // float: left;
      > a {
        padding-top:    $navbar-padding-top;
        padding-bottom: $navbar-padding-bottom;
      }
    }
  }
}

.navbar-toggle {
    // position: relative;
    // float: right;
    // margin-right: 15px;
    // padding: 9px 10px;
    // margin-top: 11px;
    // margin-bottom: 11px;
    // background-color: transparent;
    // background-image: none;
    // border: 1px solid transparent;
    // border-radius: 2px;

  .icon-bar {
    transition: transform .2s;
  }

  &:not(.collapsed) {
    .icon-bar:nth-child(2) {
      transform: rotate(45deg);
      position: absolute;
      top: 15px
    }
    .icon-bar:nth-child(3) {
      opacity: 0;
      height: 10px;
    }
    .icon-bar:nth-child(4) {
      transform: rotate(-45deg);
      position: absolute;
      top: 11px
    }
  }
}

.navbar {
  border:0px;
}
.navbar-right {
  li.active {
    background-color: white;
    color:darken($brand-primary, 10%);
  }
}

.navbar-inverse .navbar-toggle {
  border-color: transparent;
  &:hover {
    background-color: darken($brand-primary, 10%);
  }
  &:focus {
    background-color: transparent;
  }
}


.navbar .divider-vertical {
  height: $line-height-computed;
  margin: $navbar-padding-top ( $navbar-padding-horizontal / 2 ) $navbar-padding-bottom;
  border-left: 1px solid $gray-light;
}

.navbar-inverse .divider-vertical {
  border-left-color: rgba( #fff, 0.26 );
}

@media (max-width: 767px) {
  .navbar-collapse .nav > .divider-vertical {
    display: none;
  }
}
