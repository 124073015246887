#modalSubscription .modal.in .modal-dialog {
  transform:none;
}

#modalSubscription .modal-dialog{
  flex-direction: row;
}

#modalSubscription.modal.fade .modal-dialog{
  transform: translateY(25%);
}

.subscription {
  // Generic modifiers
  &__data--loading {
    visibility: hidden;
  }
  &__data--reloading {
    opacity: 0.5;
    pointer-events: none;
  }

  &__status {
    &--success {
      color: $brand-success;
    }
    &--warning {
      color: $brand-warning;
      font-size: 16px;
      font-weight: bold;
    }
    &--danger {
      color: $brand-danger;
      font-size: 16px;
      font-weight: bold;
    }
  }

  // Generic elmeents
  &__row {
    display: flex;
    justify-content: space-evenly;

    @media screen and (max-width: 665px) {
      flex-direction: column;
    }
  }

  &__col {
    box-sizing: content-box;
    //width: 100%;
    //max-width: 24rem;
    padding: 1.5rem;
  }

  // Semantical elements
  &__card {
    width: 670px;
    margin-top: 15px;
    border-radius: 25px;
    background-color: #fff;
    box-shadow: 0px 0px 10px 5px;

    @media screen and (max-width: 665px) {
      width: 280px;
    }
  }

  &__hero {
    position: relative;
    background: $sw-gradient-primary;

    color: white;
    font-size: 16px;
    text-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);

    &__badge,
    &__title {
      font-family: "Helvetica Neue";
      font-weight: 300;
      letter-spacing: 1px;
      text-shadow: none;
    }

    &__title {
      margin-bottom: 0.25em;
      opacity: 0.75;

      font-size: 12px;
      text-transform: uppercase;
    }
    &__badge {
      display: inline-block;
      padding: 0.125em 0.5em;

      border-radius: 2.5px;
      background: rgba(0, 0, 0, 0.166666);

      font-size: 25px;
    }

    &__price--original {
      vertical-align: text-top;
      opacity: 0.75;
      font-size: 18px;
      font-weight: normal;
      text-decoration-line: line-through;
    }
    &__price--promotion {
      color: #ffed63;
    }

    &__text-gold {
      color: #ffed63;
    }

    &__text-pink {
      color: #ee5767;
    }

    &__action-btn {
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
      border-radius: 3px;
      border: none;
      background: none;
      opacity: 0.66;
      display:none;

      @media screen and (max-width: 665px) {
        display:block;
      }
    }
    &__action-btn:hover {
      //background: rgba(0, 0, 0, .25);
      opacity: 1;
    }
    &__action-btn:active {
      background: rgba(0, 0, 0, 0.33);
      opacity: 1;
    }
  }

  &__header {
    font-family: "Helvetica Neue";
    font-weight: 300;
    padding: 1.5rem;
    background: #fbfbfb;

    &--warning {
      background: #fcfaf7;
    }
    &--error {
      background: #fcf9f8;
    }

    &.subscriptionFinalised{
      text-align: center;
      font-size:16px;
    }
    
    .selection {
      box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.5);
      margin-left: 40px;
      border-radius: 30px;
      width: 255px;
      padding: 1rem;
      font-size: 12px;
      color: #999;

      @media screen and (max-width: 665px) {
        margin: 25px 0 0 0;
      }

      .chosenPlanLabel {
        margin-top: 5px;
      }

      .chosenPlan {
        font-weight: 900;
        font-size: 16px;
        text-transform: uppercase;
        margin-top: 5px;
        color: grey;
      }

      .recurrence {
        margin: 10px 0;
      }

      .btn {
        background-color: #e85b95;
        color: white;
        font-size: 15px;
        padding: 0.5rem 0.75rem;
        border-radius: 20px;
        width: 100%;
        text-transform: uppercase;
        margin-top: 10px;
      }

      .additionalInfo {
        display: flex;
        flex-direction: column;
        gap: 2px;
        align-items: flex-start;
        text-align: start;
        margin-top: 20px;
        padding: 0 15px;
        font-size: 12px;

        .item {
          display: flex;
          align-items: flex-start;
          margin-bottom: 2px;
        }
      }
    }
  }

  &__row-plans {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    max-width: none;
    font-family: "Arial";
  }
  &__plan {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    border-radius: 30px;
    border: 1px solid #e85b95;
    font-weight: normal;
    color: grey;
    cursor: pointer;
    width: 255px;
    padding: 0 0 0.5rem 0;

    &.selected {
      background-color: #e85b95;
      color: white;

      .additionalInfo {
        color: #fffeee;
      }
    }

    &__specialTag {
      display: flex;
    }

    .mainInfo {
      display: flex;
      justify-content: space-around;
      -webkit-box-align: center;
      align-items: center;
      height: 100%;
      width: 100%;

      .name {
        display: flex;
        -webkit-box-pack: end;
        justify-content: flex-end;
        -webkit-box-align: center;
        align-items: center;
        height: 100%;

        .offerTitle {
          font-style: normal;
          //min-width: 140px;
          font-weight: 900;
          font-size: 17px;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          padding: 6px 0px 0px;
        }
      }

      .priceContainer {
        display: flex;
        flex-direction: column;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        height: 100%;
        margin-bottom: 0px;
        margin-top: 1.5rem;

        .price {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          height: 25px;

          .priceLeft {
            font-weight: 800;
            font-size: 35px;
            line-height: 123.4%;
          }

          .priceRight {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            text-align: left;
            padding-left: 4px;
          }
        }

        .gain {
          font-style: normal;
          font-weight: 500;
          font-size: 11px;
          line-height: 123.4%;
        }
      }

      &__content {
        padding: 1.5rem 0rem;
        color: #666;
        text-shadow: none;

        .pitch {
          font-size: 11px;
          color: #ef111e;
          font-weight: bold;
          text-transform: uppercase;
        }
      }

      &__price {
        font-size: 18px;
      }

      &__description {
        font-size: 11px;
      }
    }

    .additionalInfo {
      color: #999;
      font-size: 13px;
      line-height: 123.4%;
      -webkit-box-align: center;
      align-items: center;
      text-align: center;
      width: 100%;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      text-transform: unset;
    }

    .oldPrice {
      color: #666;
      text-decoration: line-through;
    }

    &:hover {
      border-color: #bbb;
    }

    &--special {
      &,
      &:hover {
        border: 2px solid #ef111e;
      }

      .subscription__plan__price,
      .subscription__plan__description {
        color: #ef111e;
      }
    }

    &--active {
      &,
      &:hover {
        color: #666;
        border-color: $brand-success;
        box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.15);
      }
      &__title {
        color: $brand-success;
        font-weight: bold;
      }
    }
  }

  &__bank-card-infos {
    color: #666;
    font-family: "Helvetica Neue";
    font-weight: 300;
    letter-spacing: 0.5px;

    &__status {
      font-size: 16px;
      font-weight: 500;
    }
    &__status-description {
      font-size: 12px;
      color: #888;
    }
  }

  &__invoices {
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.066);
  }

  &__invoice {
    padding: 1.5rem 2rem;
    border-radius: 5px;
    border-bottom: 1px solid #eee;

    color: #666;
    font-family: "Helvetica Neue";

    &--inactive {
      opacity: 0.5;
    }

    &__label {
      margin-left: 0.5rem;
    }

    &__price {
      margin-left: 0.5rem;

      &--original {
        font-size: 0.87em;
        text-decoration-line: line-through;
      }
    }
  }

  &__default {
    max-width: 20rem;
    margin: auto;
    margin-bottom: 2rem;
    padding: 2rem;

    color: #000;
    text-align: center;
    opacity: 0.25;
  }
  &__default-icon {
    font-size: 4em;
    margin-bottom: 1rem;
  }
}

.closeButton {
  top: 0;
  right: 0.5rem;
}


.subcribeFormContainer {
  margin-bottom: 15px;
}

.subscribeFields{
  width: 48%;
  font-size: 15px;
  padding: 0.5rem 0.75rem;
  border-radius: 10px;
  border-width:1px;
  border-color: #ee5767;
}

@media (max-width: $screen-sm-min) {
  .subscribeFields{
    width: 100%;
    border-color: #e85b95;
    margin-bottom: 10px;
  }
}

.subscribeBtn{
  background-color: #ee5767;
  color: white;
  font-size: 15px;
  padding: 0.5rem 0.75rem;
  border-radius: 10px;
  width: 100%;
  text-transform: uppercase;
  margin-top: 10px;
  border-width:1px;
}