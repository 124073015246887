.tile-header {
  margin-bottom: $line-height-computed;

  h1, h2, h3, h4, h5, h6, .pager {
    margin-top: $line-height-computed / 2;
  }

  small {
    color: $gray;
    font-size: $font-size-small;
    display: block;

    a {
      color: $gray;

      &:hover,
      &:focus {
        color: $brand-primary;
      }
    }
  }

  .pager {
    margin-bottom: 0;
  }
}

@media screen and (min-width: $screen-sm-max) {
  .tile-header {
    small {
      display: inline;
    }
  }
}
