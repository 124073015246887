.review {
    max-width: 48rem;
    margin: auto;

    &__body {
        display: flex;

        &__note {
            flex-shrink: 1;
            margin-right: 1.5rem;
            font-size: 3rem;
        }
        &__content {
            flex-grow: 1;
            word-break: break-word;
        }
    }

}