.panel-subzone {
  position: relative;
  background-color: rgb(253, 253, 253);

  &::before, &::after {
    position: absolute;
    top: -10px;
    left: 50%;
    margin-left: -5px;
    @include triangle(top, rgb(253, 253, 253), 10px, 10px);
  }
  &::before {
    top: -11px;
    border-bottom-color: #e4e4e4;
  }
}
