.dot-online,
.dot-offline {
  border-radius: 50%;
  display: inline-block;
  @include size(10px, 10px);
  margin-left: 4px;
}

.dot-online {
  background-color: $brand-success;
}

.dot-offline {
  background-color: $gray-light;
}
