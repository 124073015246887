//
// Variables
// --------------------------------------------------


//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base:              #000 !default;
// $gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:             #4A4A4A !default;   // #333
$gray:                  #9B9B9B !default; // #555
$gray-lite:             #DADADA !default;
$gray-light:            #EDEDED !default; // #777
$gray-lighter:          #FAFAFB !default; // #eee

$brand-primary:         #EE5767 !default;
$brand-secondary:       #76B2F7 !default;
$brand-success:         #68D72E !default;
$brand-info:            $brand-secondary !default;
$brand-warning:         #F39631 !default;
$brand-danger:          #D54A29 !default;



//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg:               $gray-lighter !default;
//** Global text color on `<body>`.
// $text-color:            $gray-dark !default;

//** Global textual link color.
$link-color:            $brand-primary !default;
//** Link hover color set via `darken()` function.
$link-hover-color:      darken($link-color, 15%) !default;
//** Link hover decoration.
// $link-hover-decoration: underline !default;


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  Circular, "Helvetica Neue", Helvetica, Arial, sans-serif !default;
// $font-family-serif:       Georgia, "Times New Roman", Times, serif !default;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
// $font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace !default;
// $font-family-base:        $font-family-sans-serif !default;

$font-size-base:          14px !default;
$font-size-large:         ceil(($font-size-base * 1.25)) !default; // ~18px
// $font-size-small:         ceil(($font-size-base * 0.85)) !default; // ~12px
$font-size-medium:         ceil(($font-size-base * 1.14)) !default; // ~16px
//
// $font-size-h1:            floor(($font-size-base * 2.6)) !default; // ~36px
// $font-size-h2:            floor(($font-size-base * 2.15)) !default; // ~30px
// $font-size-h3:            ceil(($font-size-base * 1.7)) !default; // ~24px
// $font-size-h4:            ceil(($font-size-base * 1.25)) !default; // ~18px

// ** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.428571429 !default; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)) !default; // ~20px

//** By default, this inherits from the `<body>`.
// $headings-font-family:    inherit !default;
// $headings-font-weight:    500 !default;
// $headings-line-height:    1.1 !default;
// $headings-color:          inherit !default;


//== Iconography
//
//## Specify custom location and filename of the included Glyphicons icon font. Useful for those including Bootstrap via Bower.

//** Load fonts from this directory.

// [converter] If $bootstrap-sass-asset-helper if used, provide path relative to the assets load path.
// [converter] This is because some asset helpers, such as Sprockets, do not work with file-relative paths.
// $icon-font-path: if($bootstrap-sass-asset-helper, "bootstrap/", "../fonts/bootstrap/") !default;
$icon-font-path:          "../fonts/" !default;

//** File name for all font files.
// $icon-font-name:          "swingsicons" !default;
//** Element ID within SVG icon file.
// $icon-font-svg-id:        "swingsicons" !default;



//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

// $padding-base-vertical:     6px !default;
// $padding-base-horizontal:   12px !default;
//
// $padding-large-vertical:    10px !default;
// $padding-large-horizontal:  16px !default;
//
// $padding-small-vertical:    5px !default;
// $padding-small-horizontal:  10px !default;
//
// $padding-xs-vertical:       1px !default;
// $padding-xs-horizontal:     5px !default;
//
// $line-height-large:         1.3333333 !default; // extra decimals for Win 8.1 Chrome
// $line-height-small:         1.5 !default;

$border-radius-base:        2.5px !default;
$border-radius-large:       5px !default;
$border-radius-small:       1px !default;

//** Global color for active items (e.g., navs or dropdowns).
// $component-active-color:    #fff !default;
//** Global background color for active items (e.g., navs or dropdowns).
// $component-active-bg:       $brand-primary !default;

//** Width of the `border` for generating carets that indicator dropdowns.
// $caret-width-base:          4px !default;
//** Carets increase slightly in size for larger components.
// $caret-width-large:         5px !default;



//== Navbar
//
//##

// Basics of a navbar
$navbar-height:                    56px !default;
$navbar-margin-bottom:             0;//$line-height-computed !default;
// $navbar-border-radius:             $border-radius-base !default;
// $navbar-padding-horizontal:        floor(($grid-gutter-width / 2)) !default;
// $navbar-padding-vertical:          (($navbar-height - $line-height-computed) / 2) !default;
$navbar-padding-bottom:             15px !default;
$navbar-padding-top:                ($navbar-height - $line-height-computed - $navbar-padding-bottom) !default;

// $navbar-collapse-max-height:       340px !default;

// $navbar-default-color:             #777 !default;
// $navbar-default-bg:                #f8f8f8 !default;
// $navbar-default-border:            darken($navbar-default-bg, 6.5%) !default;

// Navbar links
// $navbar-default-link-color:                #777 !default;
// $navbar-default-link-hover-color:          #333 !default;
// $navbar-default-link-hover-bg:             transparent !default;
// $navbar-default-link-active-color:         #555 !default;
// $navbar-default-link-active-bg:            darken($navbar-default-bg, 6.5%) !default;
// $navbar-default-link-disabled-color:       #ccc !default;
// $navbar-default-link-disabled-bg:          transparent !default;

// Navbar brand label
// $navbar-default-brand-color:               $navbar-default-link-color !default;
// $navbar-default-brand-hover-color:         darken($navbar-default-brand-color, 10%) !default;
// $navbar-default-brand-hover-bg:            transparent !default;

// Navbar toggle
// $navbar-default-toggle-hover-bg:           #ddd !default;
// $navbar-default-toggle-icon-bar-bg:        #888 !default;
// $navbar-default-toggle-border-color:       #ddd !default;

// Navbar avatar
$navbar-avatar-width:                       40px;

//=== Inverted navbar
// Reset inverted navbar basics
$navbar-inverse-color:                      #fff !default;
$navbar-inverse-bg:                         $brand-primary !default;
$navbar-inverse-border:                     darken($navbar-inverse-bg, 10%) !default;

// Inverted navbar links
$navbar-inverse-link-color:                 #fff !default;
$navbar-inverse-link-hover-color:           darken($navbar-inverse-bg, 17%) !default;
$navbar-inverse-link-hover-bg:              transparent !default;
$navbar-inverse-link-active-color:          $navbar-inverse-link-hover-color !default;
$navbar-inverse-link-active-bg:             $navbar-inverse-link-hover-bg !default;
$navbar-inverse-link-disabled-color:        #444 !default;
// $navbar-inverse-link-disabled-bg:           transparent !default;

// Inverted navbar brand label
$navbar-inverse-brand-color:                $navbar-inverse-link-color !default;
$navbar-inverse-brand-hover-color:          $navbar-inverse-link-hover-color !default;
// $navbar-inverse-brand-hover-bg:             transparent !default;

// Inverted navbar toggle
$navbar-inverse-toggle-hover-bg:            #333 !default;
$navbar-inverse-toggle-icon-bar-bg:         #fff !default;
$navbar-inverse-toggle-border-color:        #333 !default;



//== Navs
//
//##

//=== Shared nav styles
// $nav-link-padding:                          10px 15px !default;
// $nav-link-hover-bg:                         $gray-lighter !default;

// $nav-disabled-link-color:                   $gray-light !default;
// $nav-disabled-link-hover-color:             $gray-light !default;

//== Tabs
// $nav-tabs-border-color:                     #ddd !default;

// $nav-tabs-link-hover-border-color:          $gray-lighter !default;

// $nav-tabs-active-link-hover-bg:             $body-bg !default;
// $nav-tabs-active-link-hover-color:          $gray !default;
// $nav-tabs-active-link-hover-border-color:   #ddd !default;

// $nav-tabs-justified-link-border-color:            #ddd !default;
// $nav-tabs-justified-active-link-border-color:     $body-bg !default;

//== Pills
// $nav-pills-border-radius:                   $border-radius-base !default;
$nav-pills-active-link-color:               $gray !default;
$nav-pills-active-link-hover-bg:            transparent !default;
$nav-pills-active-link-hover-color:         $brand-primary !default;


//== Pagination
//
//##

$pagination-color:                     $gray !default;
$pagination-bg:                        transparent !default;
$pagination-border:                    transparent !default;

// $pagination-hover-color:               $link-hover-color !default;
// $pagination-hover-bg:                  $gray-lighter !default;
$pagination-hover-border:              transparent !default;
//
$pagination-active-color:              $brand-primary !default;
$pagination-active-bg:                 transparent !default;
$pagination-active-border:             transparent !default;
//
// $pagination-disabled-color:            $gray-light !default;
// $pagination-disabled-bg:               #fff !default;
$pagination-disabled-border:           transparent !default;



//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

$state-success-text:             $brand-success !default;
$state-success-bg:               #fff !default;
$state-success-border:           $brand-success !default;

$state-info-text:                $brand-info !default;
$state-info-bg:                  #fff !default;
$state-info-border:              $brand-info !default;

$state-warning-text:             $brand-warning !default;
$state-warning-bg:               #fff !default;
$state-warning-border:           $brand-warning !default;

$state-danger-text:              $brand-primary !default;
$state-danger-bg:                #fff !default;
$state-danger-border:            $brand-primary !default;



//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns:              10 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         30px !default;

$grid-xs-gutter-width:      $grid-gutter-width / 6 !default;
$grid-sm-gutter-width:      $grid-gutter-width / 4 !default;
$grid-md-gutter-width:      $grid-gutter-width / 2 !default;
$grid-lg-gutter-width:      $grid-gutter-width !default;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
// $grid-float-breakpoint:     $screen-sm-min !default;
//** Point at which the navbar begins collapsing.
// $grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;

$main-sm-columns:       8;
$sidebar-sm-columns:    2;

//== Dropdowns
//
//## Dropdown menu container and contents.

//** Background for the dropdown menu.
// $dropdown-bg:                    #fff !default;
//** Dropdown menu `border-color`.
// $dropdown-border:                rgba(0,0,0,.15) !default;
//** Dropdown menu `border-color` **for IE8**.
// $dropdown-fallback-border:       #ccc !default;
//** Divider color for between dropdown items.
// $dropdown-divider-bg:            #e5e5e5 !default;

//** Dropdown link text color.
$dropdown-link-color:            $gray !default;
//** Hover color for dropdown links.
// $dropdown-link-hover-color:      darken($gray-dark, 5%) !default;
//** Hover background for dropdown links.
// $dropdown-link-hover-bg:         #f5f5f5 !default;

//** Active dropdown menu item text color.
// $dropdown-link-active-color:     $component-active-color !default;
//** Active dropdown menu item background color.
// $dropdown-link-active-bg:        $component-active-bg !default;

//** Disabled dropdown menu item background color.
// $dropdown-link-disabled-color:   $gray-light !default;

//** Text color for headers within dropdown menus.
$dropdown-header-color:          $gray !default;

//** Deprecated `$dropdown-caret-color` as of v3.1.0
// $dropdown-caret-color:           #000 !default;



//== Progress bars
//
//##

//** Background color of the whole progress component
// $progress-bg:                 #f5f5f5 !default;
// //** Progress bar text color
// $progress-bar-color:          #fff !default;
// //** Variable for setting rounded corners on progress bar.
// $progress-border-radius:      $border-radius-base !default;
//
// //** Default progress bar color
// $progress-bar-bg:             $brand-primary !default;
// //** Success progress bar color
// $progress-bar-success-bg:     $brand-success !default;
// //** Warning progress bar color
// $progress-bar-warning-bg:     $brand-warning !default;
// //** Danger progress bar color
// $progress-bar-danger-bg:      $brand-danger !default;
// //** Info progress bar color
// $progress-bar-info-bg:        $brand-info !default;



//== List group
//
//##

//** Background color on `.list-group-item`
// $list-group-bg:                 #fff !default;
//** `.list-group-item` border color
$list-group-border-color:            $gray-lite !default;
$list-group-border-size:             2px !default;
//** `.list-group-item` border color
$list-group-item-border-color:        $gray-light !default;
$list-group-item-border-size:         1px !default;
//** List group border radius
// $list-group-border-radius:      none !default;

$list-group-padding:           10px !default;

$list-group-profiles-min-height:        340px !default;

//** Background color of single list items on hover
// $list-group-hover-bg:           #f5f5f5 !default;
//** Text color of active list items
$list-group-active-color:       #fff !default;
//** Background color of active list items
$list-group-active-bg:          $brand-primary !default;
//** Border color of active list elements
// $list-group-active-border:      $list-group-active-bg !default;
//** Text color for content within active list items
// $list-group-active-text-color:  lighten($list-group-active-bg, 40%) !default;

$list-group-item-primary-bg:      rgba($brand-primary, 0.05);
$list-group-item-primary-color:   inherit;

//** Text color of disabled list items
// $list-group-disabled-color:      $gray-light !default;
//** Background color of disabled list items
// $list-group-disabled-bg:         $gray-lighter !default;
//** Text color for content within disabled list items
// $list-group-disabled-text-color: $list-group-disabled-color !default;

// $list-group-link-color:         #555 !default;
// $list-group-link-hover-color:   $list-group-link-color !default;
// $list-group-link-heading-color: #333 !default;


//== Panels
//
//##

$panel-bg:                    #fff !default;
$panel-body-padding:          20px !default;
$panel-heading-padding:       15px 20px !default;
// $panel-footer-padding:        $panel-heading-padding !default;
// $panel-border-radius:         $border-radius-base !default;

//** Border color for elements within panels
$panel-inner-border:          $gray-light !default;
$panel-footer-bg:             #fff !default;

$panel-default-text:          $gray !default;
$panel-default-border:        $gray-light !default;
$panel-default-heading-bg:    $panel-bg !default;

// $panel-primary-text:          #fff !default;
// $panel-primary-border:        $brand-primary !default;
// $panel-primary-heading-bg:    $brand-primary !default;

// $panel-success-text:          $state-success-text !default;
// $panel-success-border:        $state-success-border !default;
// $panel-success-heading-bg:    $state-success-bg !default;

// $panel-info-text:             $state-info-text !default;
// $panel-info-border:           $state-info-border !default;
// $panel-info-heading-bg:       $state-info-bg !default;

// $panel-warning-text:          $state-warning-text !default;
// $panel-warning-border:        $state-warning-border !default;
// $panel-warning-heading-bg:    $state-warning-bg !default;

// $panel-danger-text:           $state-danger-text !default;
// $panel-danger-border:         $state-danger-border !default;
// $panel-danger-heading-bg:     $state-danger-bg !default;

$panel-action-color:            $gray !default;
$panel-action-hover-color:      $brand-primary !default;

//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.

//** Padding for `<th>`s and `<td>`s.
$table-cell-padding:            8px 20px !default;
//** Padding for cells in `.table-condensed`.
// $table-condensed-cell-padding:  5px !default;

//** Default background color used for all tables.
// $table-bg:                      transparent !default;
//** Background color used for `.table-striped`.
// $table-bg-accent:               #f9f9f9 !default;
//** Background color used for `.table-hover`.
// $table-bg-hover:                #f5f5f5 !default;
// $table-bg-active:               $table-bg-hover !default;

//** Border color for table and cell borders.
$table-border-color:            $gray-light !default;



//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

// $btn-font-weight:                normal !default;
//
// $btn-default-color:              #333 !default;
// $btn-default-bg:                 #fff !default;
// $btn-default-border:             #ccc !default;
//
// $btn-primary-color:              #fff !default;
// $btn-primary-bg:                 $brand-primary !default;
// $btn-primary-border:             darken($btn-primary-bg, 5%) !default;
//
// $btn-success-color:              #fff !default;
// $btn-success-bg:                 $brand-success !default;
// $btn-success-border:             darken($btn-success-bg, 5%) !default;
//
// $btn-info-color:                 #fff !default;
// $btn-info-bg:                    $brand-info !default;
// $btn-info-border:                darken($btn-info-bg, 5%) !default;
//
// $btn-warning-color:              #fff !default;
// $btn-warning-bg:                 $brand-warning !default;
// $btn-warning-border:             darken($btn-warning-bg, 5%) !default;
//
// $btn-danger-color:               #fff !default;
// $btn-danger-bg:                  $brand-danger !default;
// $btn-danger-border:              darken($btn-danger-bg, 5%) !default;

$btn-outline-default-color:              $gray !default;
$btn-outline-primary-color:              $brand-primary !default;
$btn-outline-success-color:              $brand-success !default;
$btn-outline-info-color:                 $brand-info !default;
$btn-outline-warning-color:              $brand-warning !default;
$btn-outline-danger-color:               $brand-primary !default;

$btn-underline-default-color:              #333 !default;
$btn-underline-default-border-color:              #333 !default;
$btn-underline-default-active-color:       #fff !default;

$btn-underline-primary-color:              $gray !default;
$btn-underline-primary-border-color:       $gray-lite !default;
$btn-underline-primary-active-color:       $brand-primary !default;

$btn-underline-success-color:              $gray !default;
$btn-underline-success-border-color:       $gray-lite !default;
$btn-underline-success-active-color:       $brand-success !default;

$btn-underline-info-color:                 $gray !default;
$btn-underline-info-border-color:          $gray-lite !default;
$btn-underline-info-active-color:          $brand-info !default;

$btn-underline-warning-color:              $gray !default;
$btn-underline-warning-border-color:       $gray-lite !default;
$btn-underline-warning-active-color:       $brand-warning !default;

$btn-underline-danger-color:               $gray !default;
$btn-underline-danger-border-color:        $gray-lite !default;
$btn-underline-danger-active-color:        $brand-primary !default;


// $btn-link-disabled-color:        $gray-light !default;

// Allows for customizing button radius independently from global border radius
// $btn-border-radius-base:         $border-radius-base !default;
// $btn-border-radius-large:        $border-radius-large !default;
// $btn-border-radius-small:        $border-radius-small !default;



//== Forms
//
//##

//** `<input>` background color
$input-bg:                       transparent !default;
//** `<input disabled>` background color
// $input-bg-disabled:              $gray-lighter !default;

//** Text color for `<input>`s
// $input-color:                    $gray !default;
//** `<input>` border color
$input-border:                   $gray-lite !default;

// TODO: Rename `$input-border-radius` to `$input-border-radius-base` in v4
//** Default `.form-control` border radius
// This has no effect on `<select>`s in some browsers, due to the limited stylability of `<select>`s in CSS.
$input-border-radius:            0 !default;
//** Large `.form-control` border radius
$input-border-radius-large:      0 !default;
//** Small `.form-control` border radius
$input-border-radius-small:      0 !default;

//** Border color for inputs on focus
$input-border-focus:             $brand-primary !default;

//** Placeholder text color
// $input-color-placeholder:        #999 !default;

//** Default `.form-control` height
// $input-height-base:              ($line-height-computed + ($padding-base-vertical * 2) + 2) !default;
//** Large `.form-control` height
// $input-height-large:             (ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) + 2) !default;
//** Small `.form-control` height
// $input-height-small:             (floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) + 2) !default;

//** `.form-group` margin
// $form-group-margin-bottom:       15px !default;

// $legend-color:                   $gray-dark !default;
// $legend-border-color:            #e5e5e5 !default;

//** Background color for textual input addons
$input-group-addon-bg:           transparent !default;
//** Border color for textual input addons
$input-group-addon-border-color: $gray-lite !default;

//** Disabled cursor for form controls and buttons.
// $cursor-disabled:                not-allowed !default;


//== Popovers
//
//##

//** Popover body background color
$popover-bg:                          #fff !default;
//** Popover maximum width
$popover-max-width:                   197px !default;
//** Popover border color
$popover-border-color:                transparent !default;
//** Popover fallback border color
// $popover-fallback-border-color:       red !default;

//** Popover title
$popover-title-color:                 $brand-primary !default;
$popover-title-bg:                    $popover-bg !default;

//** Popover content
$popover-content-color:               $gray !default;

//** Popover arrow width
$popover-arrow-width:                 8px !default;
//** Popover arrow color
// $popover-arrow-color:                 $popover-bg !default;

//** Popover outer arrow width
// $popover-arrow-outer-width:           ($popover-arrow-width + 1) !default;
//** Popover outer arrow color
// $popover-arrow-outer-color:           fade_in($popover-border-color, 0.05) !default;
//** Popover outer arrow fallback color
// $popover-arrow-outer-fallback-color:  darken($popover-fallback-border-color, 20%) !default;


//== Labels
//
//##

//** Default label background color
$label-default-bg:            $gray !default;
//** Primary label background color
// $label-primary-bg:            $brand-primary !default;
//** Success label background color
// $label-success-bg:            $brand-success !default;
//** Info label background color
// $label-info-bg:               $brand-info !default;
//** Warning label background color
// $label-warning-bg:            $brand-warning !default;
//** Danger label background color
// $label-danger-bg:             $brand-danger !default;

//** Default label text color
// $label-color:                 #fff !default;
//** Default text color of a linked label
// $label-link-hover-color:      #fff !default;



//== Modals
//
//##

//** Padding applied to the modal body
$modal-inner-padding:         50px 50px !default;

//** Padding applied to the modal title
$modal-title-padding:         10px 50px !default;
//** Modal title line-height
// $modal-title-line-height:     $line-height-base !default;

//** Background color of modal content area
// $modal-content-bg:                             #fff !default;
//** Modal content border color
// $modal-content-border-color:                   rgba(0,0,0,.2) !default;
//** Modal content border color **for IE8**
// $modal-content-fallback-border-color:          #999 !default;
$modal-content-border-radius: 3px !default;
$modal-header-color:          #fff !default;
$modal-header-bg:             #fff !default;
$modal-footer-bg:             $gray-lighter !default;
$modal-title-font-size:       16px !default;

//** Modal backdrop background color
// $modal-backdrop-bg:           #000 !default;
//** Modal backdrop opacity
$modal-backdrop-opacity:      .75 !default;
//** Modal header border color
// $modal-header-border-color:   #e5e5e5 !default;
//** Modal footer border color
// $modal-footer-border-color:   $modal-header-border-color !default;

// $modal-lg:                    900px !default;
// $modal-md:                    600px !default;
// $modal-sm:                    300px !default;



//== Alerts
//
//## Define alert colors, border radius, and padding.

// $alert-padding:               15px !default;
// $alert-border-radius:         $border-radius-base !default;
// $alert-link-font-weight:      bold !default;

// $alert-success-bg:            $state-success-bg !default;
// $alert-success-text:          $state-success-text !default;
// $alert-success-border:        $state-success-border !default;
//
// $alert-info-bg:               $state-info-bg !default;
// $alert-info-text:             $state-info-text !default;
// $alert-info-border:           $state-info-border !default;
//
// $alert-warning-bg:            $state-warning-bg !default;
// $alert-warning-text:          $state-warning-text !default;
// $alert-warning-border:        $state-warning-border !default;
//
// $alert-danger-bg:             $state-danger-bg !default;
// $alert-danger-text:           $state-danger-text !default;
// $alert-danger-border:         $state-danger-border !default;



//== Close
//
//##

$close-font-weight:           normal !default;
$close-color:                 inherit !default;
$close-text-shadow:           0 !default;
