.modal-content {
  z-index: 1;
}
.connected #main-navbar .navbar-brand {
  margin-left: 0;
}

#signupCobranding {
  position: relative;
}

.signup .container {
  position: relative;
}

#bySwingsy {
  display: none;
}

.cobranding.connected {
  #bySwingsy {
    position: relative;
    display: block;
    float: left;
    margin-left: 15px;
  }

  #swingsyLogoRawCobranding {
    position: absolute;
    @include img-retina(
      "../images/logo-alone.png",
      "../images/logo-alone@2x.png",
      58px,
      30px
    );
    background-size: cover;
    width: 58px;
    height: 30px;
    top: 4px;
    left: 49px;
  }

  #swingsyLogoTextCobranding {
    position: absolute;
    top: 34px;
    left: 35px;
  }

  #by {
    position: absolute;
    top: 21px;
  }

  .bySwingsyText {
    font-family: logoRegular;
    font-size: 12px;
    color: #fff;
  }

  @media (max-width: 320px) {
    #bySwingsy {
      margin-left: 8px;
    }

    #swingsyLogoRawCobranding {
      left: 39px;
    }

    #swingsyLogoTextCobranding {
      left: 25px;
    }
  }
}

.logoSWText {
  font-family: logoRegular;
  font-size: 23px;
  color: #fff;
}

#cobrandingBaseline {
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  font-size: 30px;
  color: #fff;
  width: 50%;
  text-align: center;
}

#cobrandingLogo {
  float: right;
  margin-right: 180px;
  background-size: cover;
  height: 50px;
  margin-top: 4px;
}

/************************  cobranding partners ********************************/

.lachrysalide.signup#mainView {
  background-color: #99cef0;
}

.lachrysalide #cobrandingLogo,
.lachrysalide.connected #main-navbar .navbar-brand {
  @include img-retina(
    "../images/partners/lachrysalide_minilogo.png",
    "../images/partners/lachrysalide_minilogo@2x.png",
    69px,
    50px
  );
  width: 69px;
  height: 50px;
  margin-top: 3px;
}

.lachrysalide #cobrandImg1 {
  position: absolute;
  bottom: 50px;
  left: 0;
}

.lachrysalide #cobrandImg2 {
  position: absolute;
  bottom: 0;
  right: -87px;
}

/********************************************************/

.france-coquine.signup#mainView {
  background-color: #ece7ed;
  background-image: url("../images/partners/france-coquine_background.jpg");
  background-repeat: repeat-y;
}

.france-coquine #cobrandingLogo,
.france-coquine.connected #main-navbar .navbar-brand {
  @include img-retina(
    "../images/partners/france-coquine_minilogo.png",
    "../images/partners/france-coquine_minilogo@2x.png",
    155px,
    50px
  );
  width: 155px;
}

.france-coquine #cobrandImg1 {
  display: none;
}

.france-coquine #cobrandImg2 {
  position: absolute;
  bottom: 0;
  right: 0;
}

/********************************************************/

.badaboom.signup#mainView {
  background-color: #000;
}

.badaboom #cobrandingLogo,
.badaboom.connected #main-navbar .navbar-brand {
  @include img-retina(
    "../images/partners/badaboom_minilogo.png",
    "../images/partners/badaboom_minilogo.png",
    62px,
    50px
  );
  width: 62px;
  height: 50px;
  margin-top: 4px;
}

.badaboom #cobrandImg1 {
  position: absolute;
  bottom: 150px;
  left: 0;
}

.badaboom #cobrandImg2 {
  position: absolute;
  bottom: 0;
  right: 0;
}

/********************************************************/

.tentations.signup#mainView {
  background-image: url("../images/partners/tentations_background.jpg");
  background-size: cover;
}

.tentations #cobrandingLogo,
.tentations.connected #main-navbar .navbar-brand {
  @include img-retina(
    "../images/partners/tentations_minilogo.png",
    "../images/partners/tentations_minilogo@2x.png",
    59px,
    50px
  );
  width: 59px;
  height: 50px;
  margin-top: 3px;
}

.tentations #cobrandImg1 {
  position: absolute;
  bottom: 150px;
  left: 0;
}

.tentations #cobrandImg2 {
  position: absolute;
  bottom: 150px;
  right: 0;
}

/********************************************************/

.anaiscaglisse.signup#mainView {
  background-color: #eaff3c;
}

.anaiscaglisse #cobrandingLogo,
.anaiscaglisse.connected #main-navbar .navbar-brand {
  @include img-retina(
    "../images/partners/anaiscaglisse_minilogo.png",
    "../images/partners/anaiscaglisse_minilogo.png",
    109px,
    50px
  );
  width: 109px;
  height: 50px;
  margin-top: 4px;
}

.anaiscaglisse #cobrandImg1 {
  position: absolute;
  bottom: 150px;
  left: 0;
}

.anaiscaglisse #cobrandImg2 {
  position: absolute;
  bottom: 150px;
  right: 50px;
}

/********************************************************/

.divinealcove.signup#mainView {
  background-image: url("../images/partners/divinealcove_background.jpg");
  background-size: cover;
}

.divinealcove #cobrandingLogo,
.divinealcove.connected #main-navbar .navbar-brand {
  @include img-retina(
    "../images/partners/divinealcove_minilogo.png",
    "../images/partners/divinealcove_minilogo@2x.png",
    92px,
    50px
  );
  width: 92px;
  height: 50px;
  margin-top: 3px;
}

.divinealcove #cobrandImg1 {
  display: none;
}

.divinealcove #cobrandImg2 {
  display: none;
}

/*
.divinealcove.signup#mainView {
  background-color: #000;
}

.divinealcove #cobrandingLogo,
.divinealcove.connected #main-navbar .navbar-brand {
  @include img-retina(
    "../images/partners/divinealcove_minilogo.png",
    "../images/partners/divinealcove_minilogo.png",
    165px,
    50px
  );
  width: 165px;
  height: 50px;
  margin-top: 4px;
}

.divinealcove #cobrandImg1 {
  position: absolute;
  bottom: 180px;
  left: 50px;
}

.divinealcove #cobrandImg2 {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
*/



/********************************************************/

.leparadis.signup#mainView {
  background-color: #000;
}

.leparadis #cobrandingLogo,
.leparadis.connected #main-navbar .navbar-brand {
  @include img-retina(
    "../images/partners/leparadis_minilogo.png",
    "../images/partners/leparadis_minilogo.png",
    169px,
    50px
  );
  width: 169px;
  height: 50px;
  margin-top: 4px;
}

.leparadis #cobrandImg1 {
  position: absolute;
  bottom: 100px;
  left: 50px;
}

.leparadis #cobrandImg2 {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

/********************************************************/

.lamarquise.signup#mainView {
  background-color: #222222;
}

.lamarquise #cobrandingLogo,
.lamarquise.connected #main-navbar .navbar-brand {
  @include img-retina(
    "../images/partners/lamarquise_minilogo.png",
    "../images/partners/lamarquise_minilogo.png",
    71px,
    54px
  );
  width: 71px;
  height: 54px;
  margin-top: 4px;
}

.lamarquise #cobrandImg1 {
  position: absolute;
  bottom: 120px;
  left: 5%;
}

.lamarquise #cobrandImg2 {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 50%;
}

/********************************************************/

.domaine7sens.signup#mainView {
  background-color: #f5333b;
}

.domaine7sens #cobrandingLogo,
.domaine7sens.connected #main-navbar .navbar-brand {
  @include img-retina(
    "../images/partners/domaine7sens_minilogo.png",
    "../images/partners/domaine7sens_minilogo.png",
    162px,
    52px
  );
  width: 162px;
  height: 52px;
  margin-top: 4px;
}

.domaine7sens #cobrandImg1 {
  position: absolute;
  bottom: -65px;
  left: 0px;
}

.domaine7sens #cobrandImg2 {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

/*@media (max-width: 1800px) {
    .lamarquise #cobrandImg1{
        bottom: 215px;
        left: 5%;
        width: 24%; 
        }
}*/

/********************************************************/

.takenclub.signup#mainView {
  background-image: url("../images/partners/takenclub_background.jpg");
  background-size: cover;
}

.takenclub #cobrandingLogo,
.takenclub.connected #main-navbar .navbar-brand {
  @include img-retina(
    "../images/partners/takenclub_minilogo.png",
    "../images/partners/takenclub_minilogo@2x.png",
    130px,
    50px
  );
  width: 130px;
  height: 50px;
  margin-top: 3px;
}

.takenclub #cobrandImg1 {
  position: absolute;
  bottom: 250px;
  left: 100px;
}

.takenclub #cobrandImg2 {
  display: none;
}

/********************************************************/

.deuxmil3.signup#mainView {
  background-image: url("../images/partners/deuxmil3_background.jpg");
  background-size: cover;
}

.deuxmil3 #cobrandImg1 {
  position: absolute;
  bottom: 250px;
  left: 50px;
}

.deuxmil3 #cobrandImg2 {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.deuxmil3 #cobrandingLogo,
.deuxmil3.connected #main-navbar .navbar-brand {
  @include img-retina(
    "../images/partners/deuxmil3_minilogo.png",
    "../images/partners/deuxmil3_minilogo.png",
    88px,
    50px
  );
  width: 88px;
  height: 50px;
  margin-top: 4px;
}

@media (max-width: 1700px) {
  .modal-content {
    width: 95%;
  }

  /********************************************************/

  .lachrysalide #cobrandImg1 {
    width: 30%;
    /*top: 50%; /* poussé de la moitié de hauteur du référent */
    /*transform: translateY(-50%); /* tiré de la moitié de sa propre hauteur */
    left: 0;
    bottom: 150px;
  }

  .lachrysalide #cobrandImg2 {
    width: 40%;
    bottom: 0;
    right: -87px;
  }

  /********************************************************/

  .badaboom #cobrandImg1 {
    width: 26%;
    left: 0;
    bottom: 150px;
  }

  .badaboom #cobrandImg2 {
    width: 40%;
    bottom: 0;
    right: 0;
  }

  /********************************************************/

  .tentations #cobrandImg1 {
    width: 33%;
    left: -20px;
  }

  .tentations #cobrandImg2 {
    width: 33%;
    right: -50px;
  }

  /********************************************************/

  .anaiscaglisse #cobrandImg1 {
    width: 26%;
    left: 0;
    bottom: 150px;
  }

  .anaiscaglisse #cobrandImg2 {
    width: 25%;
    right: 25px;
  }

  /********************************************************/

  .divinealcove #cobrandImg1 {
    bottom: 215px;
    left: 2%;
    width: 24%;
  }

  /********************************************************/

  .leparadis #cobrandImg1 {
    position: absolute;
    bottom: 100px;
    width: 25%;
    left: 0;
  }

  /********************************************************/

  .lamarquise #cobrandImg1 {
    bottom: 150px;
    left: 3%;
    width: 20%;
  }

  /********************************************************/

  .domaine7sens #cobrandImg1 {
    position: absolute;
    bottom: 0px;
    width: 30%;
  }

  /********************************************************/

  .takenclub #cobrandImg1 {
    width: 25%;
    left: 1%;
    bottom: 200px;
  }

  /********************************************************/

  .deuxmil3 #cobrandImg1 {
    width: 26%;
    left: 45px;
    bottom: 250px;
  }

}

@media (max-width: 1450px) {
  .domaine7sens #cobrandImg1 {
    display: none;
  }

  /********************************************************/

  .deuxmil3 #cobrandImg1 {
    width: 20%;
    left: 60px;
    bottom: 300px;
  }
}

@media (max-width: 1250px) {
  .modal-content {
    width: 90%;
  }

  /*.france-coquine #cobrandingBaseline{
        display:none;
    }*/
  
  /********************************************************/

  .lachrysalide #cobrandImg1,
  .badaboom #cobrandImg1,
  .anaiscaglisse #cobrandImg1,
  .takenclub #cobrandImg1 {
    display: none;
  }

  .lachrysalide #cobrandImg2 {
    width: 40%;
    bottom: 0;
    right: -87px;
  }

  /********************************************************/

  .france-coquine #cobrandImg2 {
    width: 40%;
  }

  /********************************************************/

  .tentations #cobrandImg1,
  .tentations #cobrandImg2 {
    bottom: 250px;
    width: 30%;
  }

  /********************************************************/

  .divinealcove #cobrandImg1 {
    bottom: 245px;
    width: 20%;
  }

  /********************************************************/

  .leparadis #cobrandImg1 {
    position: absolute;
    bottom: 250px;
    width: 15%;
    left: 25px;
  }

  /********************************************************/
  
  .lamarquise #cobrandImg1 {
    bottom: 245px;
    width: 20%;
  }

  /********************************************************/
  
  .deuxmil3 #cobrandImg1 {
    width: 15%;
    left: 60px;
    bottom: 400px;
  }
}

@media (max-width: 1070px) {
  .deuxmil3 #cobrandImg1,
  .deuxmil3 #cobrandImg2
  {
    display: none;
  }
}

@media (max-width: 900px) {
  .modal-content {
    width: 100%;
  }

  .lachrysalide #cobrandImg2,
  .france-coquine #cobrandImg2,
  .badaboom #cobrandImg2,
  .tentations #cobrandImg1,
  .tentations #cobrandImg2,
  .anaiscaglisse #cobrandImg1,
  .anaiscaglisse #cobrandImg2,
  .divinealcove #cobrandImg1,
  .divinealcove #cobrandImg2,
  #cobrandingBaseline,
  .leparadis #cobrandImg1,
  .leparadis #cobrandImg2,
  .lamarquise #cobrandImg1,
  .lamarquise #cobrandImg2,
  .takenclub #cobrandImg1
   {
    display: none;
  }

  #cobrandingLogo {
    margin-right: 15px;
  }
}

@media (max-width: 768px) {
  .connected #main-navbar .navbar-brand {
    margin-left: 15px;
  }

  /********************************************************/
  
  .france-coquine #main-navbar .navbar-brand {
    @include img-retina(
      "../images/logo-vertical.png",
      "../images/logo-vertical@2x.png?",
      88px,
      50px
    );
    width: 88px;
  }
}

@media (max-width: 450px) {
  .domaine7sens #cobrandingLogo,
  .domaine7sens.connected #main-navbar .navbar-brand {
    @include img-retina(
      "../images/partners/domaine7sens_minilogo.png",
      "../images/partners/domaine7sens_minilogo.png",
      120px,
      39px
    );
    width: 120px;
    height: 39px;
    margin-top: 10px;
    margin-right: -15px;
  }

  /********************************************************/
  
  .takenclub #cobrandingLogo,
  .takenclub.connected #main-navbar .navbar-brand {
    @include img-retina(
      "../images/partners/takenclub_minilogo.png",
      "../images/partners/takenclub_minilogo@2x.png",
      110px,
      42px
    );
    width: 110px;
    height: 42px;
    margin-top: 8px;
  }
}

@media (max-width: 350px) {
  #main-navbar .navbar-brand {
    width: 190px;
  }

  /********************************************************/
  
  .domaine7sens #cobrandingLogo,
  .domaine7sens.connected #main-navbar .navbar-brand {
    @include img-retina(
      "../images/partners/domaine7sens_minilogo.png",
      "../images/partners/domaine7sens_minilogo.png",
      100px,
      32px
    );
    width: 100px;
    height: 32px;
    margin-top: 15px;
  }

  /********************************************************/
  
  .takenclub #cobrandingLogo,
  .takenclub.connected #main-navbar .navbar-brand {
    @include img-retina(
      "../images/partners/takenclub_minilogo.png",
      "../images/partners/takenclub_minilogo@2x.png",
      82px,
      34px
    );
    width: 85px;
    height: 34px;
    margin-top: 11px;
  }

  /********************************************************/
  /*
  .divinealcove #cobrandingLogo,
  .divinealcove.connected #main-navbar .navbar-brand {
    @include img-retina(
      "../images/partners/divinealcove_minilogo.png",
      "../images/partners/divinealcove_minilogo@2x.png",
      63px,
      34px
    );
    width: 63px;
    height: 34px;
    margin-top: 11px;
  }*/

}
