.card {
  position: relative;
  @extend .panel;
  @extend .panel-default;
  border: none;
  overflow: hidden;

  .profile-cover {
    display: block;
    height: 160px;
    margin-left: 50%;
    @include translate(-50%, 0);
  }

  .profile-cover,
  .event-cover,
  .avantage-cover {
    background-color: $brand-primary;
  }
}

.card-heading {
  position: relative;
  @extend .panel-heading;

  .profile-picture {
    @include size(68px, 68px);
    position: absolute;
    top: 0;
    left: 50%;
    @include translate(-50%, -50%);
  }

  .profile-metas {
     font-size: $font-size-small;
     @include list-unstyled;
     @include clearfix;

     .sicon {
       color: $brand-primary;
       font-size: $font-size-medium;
       vertical-align: middle;
     }

     .profile-location {
       float: left;
       max-height: 17px; // to avoid display problems on multiple cards on screen
     }

     .profile-state {
       float: right;
     }
  }

  .profile-identity{
    min-height:65px;
  }

  .profile-name {
    color: $gray-dark;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1px;
  }

  .profile-screenname,.profile-address {
    max-height: 23px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    .profile-location-icon {
      color: $brand-primary;
      margin-right: $font-size-base / 2;
    }
  }
}

.card-body {
  @extend .panel-body;
  /*color: $gray;*/
  .profile-situation {
    float: left;
  }
  .profile-reviews-count {
    float: right
  }
}

.card-hover-link {
  position: absolute;
  top: 0;
  left: 0;
  @include size(100%, 100%);

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

a.card-hover-link {
  @include text-hide();
}

ul.card-hover-link {
  @include list-unstyled;
}

.card-profile {
  .card-heading {
    height: 150px;
  }
  .card-available {
    opacity:0.7;
    position: absolute;
    top: 0;
    padding: 5px;
    color: black;
    z-index: 10;
    img {
      max-width: 100%;
    }
  }
  .homme {
    font-size: small;
    color:#76b2f7
  }
  .femme {
    font-size: small;
    color:#ee5767
  }
}

.card-event {
  .card-heading {
    min-height: 118px;
  }

  .event-pour-qui {
    td {
      padding-left: 15px;
    }
  }
  .card-hover-link {
    text-align: center;

    &:hover {
      background-color: rgba($brand-primary, 0.8);

      > li {
        display: inline-block;
      }
    }

    > li {
      display: none;
      vertical-align: middle;
      margin-top: 25%;
      margin-bottom: 30px;

      > a {
        display: block;
        width: 155px - $grid-gutter-width;
        height: 155px - $grid-gutter-width;
        background-color: #fff;
        border-radius: 50%;
        padding: $grid-gutter-width / 2;
        box-sizing: content-box;

        &:hover {
          text-decoration: none;
        }

        > span {
          display: block;
          margin: 0 auto;
        }
      }

      &.event-link {
        > a {
          > span {
            font-size: 48px;
            line-height: 48px;
            margin-top: 15px;
          }
        }
      }
      &.send-invit {
        > a {
          > .sicon {
            font-size: 36px;
            line-height: 36px;
          }
        }
      }

      &:first-child {
        margin-right: $grid-gutter-width / 2;
      }
    }
  }
}

.card-avantage {
  .card-heading {
    color: $brand-primary;
    min-height: auto;

    > .sicon {
      margin-right: 0.5em;
    }
  }

  .avantage-detail {
    float: left;
    width: 55%;
    border-right: 1px solid $panel-inner-border;
    padding: $panel-heading-padding;
  }

  .broadcast-detail {
    float: left;
    width: 100%;
    border-right: 1px solid $panel-inner-border;
    padding: $panel-heading-padding;
  }

  .avantage-title {
    margin-top: 0;
  }

  .avantage-summary {
    color: $gray;
  }

  .avantage-club,
  .avantage-link {
    position: relative;
    float: left;
    width: 45%;
    padding: $panel-heading-padding;
    padding-left: 45px;
    border-left: 1px solid $panel-inner-border;
    margin-left: -1px;

    .sicon {
      font-size: $font-size-large;

      &::before {
        color: $brand-primary;
        position: absolute;
        left: 15px;
        top: 50%;
        @include translate(0, -50%);
      }
    }
  }

  .avantage-club {
    border-bottom: 1px solid $panel-inner-border;

    .club-title {
      margin: 0;
      font-size: $font-size-base;
    }

    .club-address {
      color: $gray;
    }
  }

  .avantage-link {
    word-wrap: break-word;

    a {
      color: $gray;

      &:hover,
      &:focus {
        color: $brand-primary;
      }
    }
  }
}

@media screen and (max-width: $screen-lg-min) {
  .card-heading {
    min-height: 111px;

    .profile-name {
      font-size: $font-size-medium;
    }
    .profile-screenname {
      font-size: $font-size-small;
    }
  }
  .card-avantage {
    min-height: 340px;
    margin-bottom: 35px;
    .card-heading {
      min-height: auto;
    }
  }

  .card-travel{
    min-height: 220px;
  }
}

@media screen and (min-width: $screen-sm-min) {
  .card-event {
    .event-cover {
      width: 33%;
    }

    .event-content {
      width: 67%;
    }

    .card-hover-link {
      &:hover {
        > li {
          margin-top: 0;
          @include make-vertical-align;
        }
      }
    }
  }
}
