#modalSignin {
  .btn{
    border-radius: 0;
  }

  hr {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    border-top: 1px solid #fafafb;
  }

  .form-group {
    padding-top: 10px;
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      margin-left: 2px;
      margin-right: 2px;
      margin-top: -10px;
      border: none
    }
    // padding-bottom: 20px;
    .btn-group {
      padding-top: 7px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      label {
        //min-width: 150px;
        margin: 5px auto;
      }
      /*@media(max-width:767px){
        label {
          min-width: 100%;
        }
      }*/
    }
  }

  .card {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    margin: 5px;
    /* On mouse-over, add a deeper shadow */
    &:hover {
      box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }
    /* Add some padding inside the card container */
    .card-container {
      padding: 2px 16px;
    }
    @media(max-width:767px){
      .card-container {
        padding: 2px 26px;

      }
    }
  }

}

//utils
.mb-10 {

}
.mb-10 {

}
.pb-10 {

}

.center_div{
  margin: 0 auto;
  width:85% /* value of your choice which suits your alignment */
}