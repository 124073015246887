.sidebar {
  .proposing {
    .proposing-title {
      color: $gray;
      margin-bottom: 0;
    }
  }

  .btn-swingsy-travel {
    font-size: $font-size-medium;
    text-align: left;
    padding-right: $grid-gutter-width;
    padding-top: 10px;
    padding-bottom: 10px;

    > .sicon {
      font-size: 20px;
      vertical-align: center;
    }
  }

  .sidebarContainer{
    padding: 1rem 1.25rem;
    /*margin-top: 2.1rem;*/
    margin-bottom: 2rem;
    border-radius: 2px;
    background-color: rgba(255, 255, 255, .66);
    box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.38);
    text-transform: uppercase;
    text-align: center;

    .header{  
      text-align: center;
      font-weight: bold;
      color:$brand-primary;
      margin:0 0 5px 0;
    }

    a.blockItem{
      display: block;
      margin-top: 15px;
    }

    .cover{
      display: block;
      width: 100%;
      margin: 0 auto;
    }

    .text{
      margin:5px 0;
      color:$brand-primary;
      font-size:1.1rem;
    }

    .footer {
      display: block;
      padding:2px 5px;
      margin-top:8px;
      background-color:$brand-primary;
      color: $gray-lighter;
      box-shadow: 0 1px .5rem rgba(62, 62, 62, 0.86);

      &:hover{
        text-decoration: none;
      }

    }
  }


}

.available-div {
  margin-top:15px;
}

.side-checkbox {
  float: left;
  width: 22px;
  height: 19px;
}

.profile-micro,
.menu-sidebar,
.proposing {
  margin-bottom: $line-height-computed;
}

.profile-micro {
  .profile-picture {
    @include size(52px, 52px);
    float: left;
    margin-right: $grid-gutter-width / 2;
  }

  .profile-name {
    margin-bottom: 4px;
  }

  a {
    color: $gray-lite;
  }

  .profile-description {
    color: $gray;
  }
}

.menu-sidebar,
.menu-proposing {
  @include list-unstyled;

  > li {
    > a {
      font-size: $font-size-medium;
      line-height: $font-size-medium * 2;
      color: $gray-dark;

      &:hover,
      &:focus {
        color: $brand-primary;
        text-decoration: none;
      }
    }

    &.disabled > a {
      color: $nav-disabled-link-color;

      &:hover,
      &:focus {
        color: $nav-disabled-link-hover-color;
        text-decoration: none;
        background-color: transparent;
        cursor: $cursor-disabled;
      }
    }
  }


}

.sidebar {
  &__broadcast {
    padding-top: 1.5rem;
  }
  
}


