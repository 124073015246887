.btn-filters {
  background-color: transparent;
  padding-left: 5px;
  padding-right: 5px;

  > .sicon {
    color: $brand-primary;
    font-size: $font-size-small;
  }

  &:hover,
  &:focus {
    box-shadow: none;
    color: $brand-primary;
  }
}

.filters {
  margin-bottom: $grid-gutter-width;

  .control-label {
    color: $gray;
    font-weight: normal;
  }
}
