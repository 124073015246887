.sw-datefield {
    display: flex;
    align-items: stretch;

    &__input {
        flex-grow: 1;
        // https://stackoverflow.com/a/27460682/4317384
        min-width: 1px;
    }
    &__separator { flex-shrink: 1; }
}
