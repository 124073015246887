// Labels

@mixin label-outline-variant($color) {
  color: $color;
  background-color: transparent;
  border: 1px solid $color;

  &[href] {
    &:hover,
    &:focus {
      border-color: darken($color, 10%);
    }
  }
}
