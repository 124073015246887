.sexynews-box {

    &__header {
        margin: 0;
        margin-bottom: 1rem;
        font-family: 'Helvetica Neue', 'Helvetica';
        color: $brand-primary;
        font-size: 12px;
        text-transform: uppercase;

        &__icon {
            float: left;
            margin-right: 1rem;
            font-size: 20px;
        }
        &__img {
            width:30px;
            height:30px;
        }
    }

    &__body {
        padding: 1rem 1.25rem;
        margin-bottom: .5rem;
        border-radius: 2px;
        background-color: rgba(255, 255, 255, .66);
        box-shadow: 0 1px .25rem rgba(0, 0, 0, .125);
        transition: box-shadow .1s;

        color: #888;
        line-height: 1.4;
        font-size: 13px;
        cursor: pointer;

        &:hover {
            background-color: rgba(255, 255, 255, 1);
            box-shadow: 0 .25rem 1rem rgba(0, 0, 0, .125);
            color: $text-color;
        }

        &__title {
            color: #ee5767;
            font-weight: bold;
        }
    }
}
