// Common form controls
//
// Shared size and type resets for form controls. Apply `.form-control` to any
// of the following form controls:
//
// select
// textarea
// input[type="text"]
// input[type="password"]
// input[type="datetime"]
// input[type="datetime-local"]
// input[type="date"]
// input[type="month"]
// input[type="time"]
// input[type="week"]
// input[type="number"]
// input[type="email"]
// input[type="url"]
// input[type="search"]
// input[type="tel"]
// input[type="color"]

.form-group2 {
  margin-bottom: 20px;
}

input:focus-visible {
  border: 1px solid #ee5767 !important;
  outline: none !important;
}

.form-checkBtn {
  color: #ee5767;
  background-image: none;
  background-color: transparent;
  border: 1px solid #ee5767;
  border-radius: 8px;
  cursor: pointer;
}

.form-checkBtn.active {
  color: #fff;
  background-color: #ee5767;
}

.form-control {
  border: none;
  border-bottom: 2px solid $input-border;
  box-shadow: none;

  &:focus {
    box-shadow: none;
  }
}

textarea.form-control {
  border: 2px solid $input-border;
}

.form-checkbox {
  float: left;
  width: 22px;
  height: 23px;
}

.pink {
  color: #ee5767;
}

// Horizontal forms
//
// Horizontal forms are built on grid classes and allow you to create forms with
// labels on the left and inputs on the right.

.form-horizontal {
  position: relative;

  .form-group {
    padding-top: 10px;
    margin-bottom: 10px;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      @include make-responsive-col-gutter-width(2, margin);
      margin-top: -10px;
      border-top: 1px solid $gray-light;
    }
  }

  @media (min-width: $screen-sm-min) {
    .control-label {
      text-align: left;

      &.col-sm-#{$grid-columns},
      &.col-md-#{$grid-columns},
      &.col-lg-#{$grid-columns} {
        margin-bottom: 10px;
      }
    }
  }
}

// hack for centered text inside "number" input
input[type="number"].number-center {
  padding-left: 10px;
  padding-right: 0;
  text-align: center;
}

// fieldset
fieldset {
  margin-bottom: 60px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid $gray-light;
  border-top: 1px solid $gray-light;
}

// Upload Drop zone
//

.drop-box {
  border: 5px dashed $gray-light;
  width: 100%;
  min-height: 100px;
  text-align: center;
  padding-top: $grid-gutter-width;
  padding-bottom: $grid-gutter-width;
  margin-bottom: $line-height-computed;

  &.dragover {
    border-color: $brand-primary;
    cursor: grab;
  }

  p {
    color: $gray;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.material-switch > input[type="checkbox"] {
  display: none;   
}

.material-switch > label {
  cursor: pointer;
  height: 0px;
  position: relative; 
  width: 40px;  
  background-color: #ee5767;
}

.material-switch > label::before {
  background: rgb(0, 0, 0);
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  content: '';
  height: 16px;
  margin-top: -8px;
  position:absolute;
  opacity: 0.3;
  transition: all 0.4s ease-in-out;
  width: 40px;
}
.material-switch > label::after {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  content: '';
  height: 24px;
  left: -4px;
  margin-top: -8px;
  position: absolute;
  top: -4px;
  transition: all 0.3s ease-in-out;
  width: 24px;
}
.material-switch > input[type="checkbox"]:checked + label::before {
  background: inherit;
  opacity: 0.5;
}
.material-switch > input[type="checkbox"]:checked + label::after {
  background: inherit;
  left: 20px;
}

.custom-slider.rzslider {
  margin:20px 0 0px 0;
}

.custom-slider.rzslider .rz-bar {
  background: #e6e6e6;
  height: 3px;
}
.custom-slider.rzslider .rz-selection {
  background: #ee5767;
}

.custom-slider.rzslider .rz-pointer {
  width: 25px;
  height: 25px;
  top: -11px;
  bottom: 0;
  background-color: #ee5767;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-radius: 13px;
  outline: none;
  box-shadow: 0 0 5px rgba(11, 11, 11, 0.3);
}

.custom-slider.rzslider .rz-pointer:after {
  display: none;
}

.custom-slider.rzslider .rz-bubble {
  bottom: 14px;
}
/*
.custom-slider.rzslider .rz-limit {
  font-weight: bold;
  color: orange;
}*/

.custom-slider.rzslider .rz-tick {
  width: 1px;
  height: 10px;
  margin-left: 4px;
  border-radius: 0;
  background: #ffe4d1;
  top: -1px;
}

.custom-slider.rzslider .rz-tick.rz-selected {
  background: orange;
}

.form-control.postTextarea{
  height: 34px;
  border-radius: 10px!important;
  overflow: hidden;
}


