.message {
  .main {
    @include make-md-column(10);
  }
}

// .col-panel-msg-contacts {
//   width: 80px;
// }
.col-panel-msg-list {
  @media (max-width: $screen-md-min) {
    width: calc(100% - 80px);
    position: absolute;
    top: 0;
    left: 77px;
    padding-left: 6px;
  }
}

.panel-msg-contacts,
.panel-msg-list {
  height: 85vh;
  display: flex;
  flex-direction: column;

  .list-contacts,
  .list-messages {
    overflow-y: scroll;
    flex-grow: 2;

    > .list-message-inner {
      // Must be separated from .list-message property `overflow-y`.
      // See https://github.com/philipwalton/flexbugs/issues/108
      display: flex;
      flex-direction: column-reverse;

      > .list-group {
        margin: 0;
      }
    }
  }
}

.panel-msg-contacts {

  .navbar {
    @media (max-width: $screen-md-min) {
      width: 80px;
      transition: width .2s;

      .container-fluid {
        padding: 0;
      }
      &.active {
        width: auto;
        background: white;
        z-index: 999;
        .navbar-search {
          padding: 0 15px;
          width: calc(100% - 44px);
          .form-control {
            width: 100%;
            padding: 6px 12px;
          }
          .input-group-addon .sicon {
            &:before {
              content: '\e90a';
            }
          }
        }
      }
    }
  }
  .navbar-search {
    margin-left: -($grid-gutter-width / 2);
    margin-right: -($grid-gutter-width / 2);

    .input-group {
      width: 100%;
    }

    @media (max-width: $screen-md-min) {
      width: 40px;
      float: left;
      border: 0;
      padding-bottom: 0;
      padding-top: 0;
      margin-left: 0;
      margin-right: 0;
      transition: width .2s;
      padding: 0;

      .form-control {
        width: 0;
        padding: 0;
        overflow: hidden;
        transition: width .2s;
      }
      .input-group-addon {
        border: none;
        cursor: pointer;
      }
    }
  }
  .navbar-right {
    @media (max-width: $screen-md-min) {
      margin: 0;
      float: right;
      margin-right: -1px;
      padding: 8px 0;
      border-left: 1px solid $gray-light;
      a {
        padding: 10px 13px;
      }
    }
  }

  .add-contact {
    z-index: 999;
    background: white;
    padding: 6px;
    border-bottom: 1px solid $gray-light;
  }

  .list-contacts {

    .contacts-entry {
      padding-left: 38px;

      .contact-picture {
        @include size(30px, 30px);
        position: absolute;
        left: 15px;
        top: 10px;
      }

      .contact-name {
        font-size: $font-size-base;
        margin-top: 0;
        margin-bottom: $font-size-base / 2;

        small {
          font-size: $font-size-base;
        }
      }

      .contact-body {
        color: $gray;
      }
    }

    .list-group {
      .list-group-item {
        border: none;
        border-bottom: 1px solid $gray-light;
        border-top: 1px solid $gray-light;

        &:first-child {
          border-top: none;
        }
      }
    }

    .list-group-item {
      // Active class on item itself, not parent
      &.active,
      &.active:hover,
      &.active:focus {
        color: $list-group-active-color;

        // Force color to inherit for custom content
        .list-group-item-heading,
        .list-group-item-heading > small,
        .list-group-item-heading > .small {
          color: inherit;
        }
        .list-group-item-text {
          color: $list-group-active-text-color;
        }
      }
    }

    

    // mobile only
    @media (max-width: $screen-md-min) {
      width: 80px;
      .contacts-entry {
        padding-left: 0;

        .contact-picture {
          @include size(50px, 50px);
          left: 0;
          top: 0;
          position: relative;
          margin: 0 auto;
        }

        .contact-name {
          display: none;
        }

        .contact-body {
          display: none;
        }
      }
    }
  }
}

.panel-msg-list {
  @media (max-width: $screen-md-min) {
    border-left: 1px solid #DDD;

    .panel-heading {
      max-height: 56px;
      h4 {
        margin: 0;
        font-size: 1.25rem;
      }
      .profile-metas {
        font-size: 1rem;
        margin: 3px 0 0;
        .sicon-location-pin-target-2 {
          font-style: 1.3em;
        }
      }
      .panel-action {
        margin: -5px -15px 0 0;
        li {
          float: left;
          .sicon {
            font-size: 16px;
          }
        }
      }
    }
  }

  .contact-profile {
    .profile-name {
      color: $gray-dark;
      max-width: calc(100% - 90px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      small {
        color: $gray;
      }
    }

    .profile-location {
      .sicon {
        color: $brand-primary;
        font-size: $font-size-medium;
      }
    }
  }

  .panel-action {
    .sicon {
      font-size: $font-size-h3;
    }

    .open {
      > a,
      > a:focus,
      > a:hover {
        background-color: transparent;
        border-color: transparent;
        color: $brand-primary;
      }
    }
  }

  .list-messages {

    .list-group-item {
      border: none;
    }

    .messages-entry {
      padding-left: 100px;
      padding-right: 85px;

      .contact-picture {
        @include size(40px, 40px);
        position: absolute;
        left: 15px;
        top: 10px;
      }

      .contact-name {
        color: $brand-primary;
        font-size: $font-size-base;
        margin-top: 0;
        @media (min-width: $screen-md-min) {
          small {
            font-size: smaller;
          }
        }
      }

      .message-date {
        color: $gray;
      }

      .message-body {
        font-size: $font-size-medium;
      }

      .message-footer {
        display: none;

        small {
          color: $gray;
        }
      }

      
    }

    .list-group-item {
      &:last-child {
        .message-footer {
          display: block;
        }
      }
    }

    @media (max-width: $screen-md-min) {
      .messages-entry {
        padding: 0;
      }
      .messages-entry-avatar {
        display: none;
      }
      .contact-name {
        a {
          max-width: 80%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        small {
          font-size: 10px;
          line-height: 1.8;
          max-width: 20%;
          text-align: right;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        message-body {
          margin-bottom: 1.5em;
        }
      }
    }
  }

  .panel-footer {
    padding: 0;

    .fastReplies{
      background-color: #AAA;
      border-radius: 12px;
      margin:12px;
      padding: 8px;
      text-align: center;

      .header{
        margin-bottom: 5px;
        color: #FFF;
      }

      .btn {
        color:#AAA;
      }

      .btn-default:focus, .btn-default:focus-visible {
        border-color: #EE5767;
        outline:none;
        color: #EE5767;
    }
    }
  }

  .message-input {
    position: relative;
    float: left;
    width: 80%;

    > div:first-child {
      font-size: $font-size-medium;
      outline: none;
      padding: 5px 5px 5px 5px;
      height: 150px;
      overflow-y: scroll;
    }

    .menu-imoticons {
      @include make-vertical-align(absolute);
      right: 0;

      .dropdown-toggle {
        color: $gray;
        font-size: $font-size-h3;

        &:hover,
        &:focus {
          color: $brand-primary;
          text-decoration: none;
        }
      }
    }
  }

  .message-buttons {
    display: flex;
    flex-direction: column;
    height: 100px;
  }

  .btn.add-picture,
  .btn.send {
    border-color: $gray-light;
    border-radius: 0;
    border-right: 0;
    margin-top: -1px;
    padding: 10px 16px;

    > .sicon {
      font-size: $font-size-h4;
      vertical-align: -2px;
      @media (max-width: $screen-md-min) {
        margin-right: 0;
      }
      @media (min-width: $screen-md-min) {
        margin-right: $grid-gutter-width / 2;
      }
    }
  }
  .btn.send {
    flex-grow: 2;
    border-bottom: 0;
    font-size: $font-size-medium;

    > .sicon {
      font-size: $font-size-h3;
    }
  }
}
