.dashboard {
    &__events {

        .card-event {
            
            .event-cover,
            .event-content {
                width: 100%;
            }
            
            .event-cover {
                position: relative;
                max-height: 175px;
                overflow: hidden;

                &::before {
                    content: ' ';
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    background: linear-gradient(0deg, rgba(0, 0, 0, .33), transparent, transparent);
                }
            }
        }

    }

    .modal-dialog {
        .modal-body {
          padding: 0 10px 10px 10px;
          height: 100%;
          .lightbox-image-caption {
            font-size: .8em;
            top:auto;
            bottom: 0;
    
          }
        }
      }
}
