.panel-recherche-controller {
  .panel-body {
    position: relative;
  }

  .control-label { color: $gray; }

  p.age-label {
    font-size: $font-size-small;
    margin-bottom: -5px;
  }
  p.age-label-her { color: $brand-primary; }
  p.age-label-him { color: $brand-secondary; }
  .dropdown-footer {
    padding: 15px 30px;
    margin: 30px -30px -15px;
    background: $gray-lighter;
    text-align: left;
  }
}

body.recherche {
  .profile-list {
    @media screen and (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
      li:nth-of-type(2n+1) {
        clear: both;
      }
    }

    @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      li:nth-of-type(3n+1) {
        clear: both;
      }
    }

    @media screen and (min-width: $screen-md-min) {
      li:nth-of-type(4n+1) {
        clear: both;
      }
    }
  }
}
