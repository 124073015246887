@mixin make-responsive-col-gutter-width($divisor: 2,$filter: padding) {
  #{$filter}-left: floor(($grid-xs-gutter-width / $divisor));
  #{$filter}-right: floor(($grid-xs-gutter-width / $divisor));

  @media (min-width: $screen-sm-min) {
    #{$filter}-left: floor(($grid-sm-gutter-width / $divisor));
    #{$filter}-right: floor(($grid-sm-gutter-width / $divisor));
  }
  @media (min-width: $screen-md-min) {
    #{$filter}-left: floor(($grid-md-gutter-width / $divisor));
    #{$filter}-right: floor(($grid-md-gutter-width / $divisor));
  }
  @media (min-width: $screen-lg-min) {
    #{$filter}-left: floor(($grid-lg-gutter-width / $divisor));
    #{$filter}-right: floor(($grid-lg-gutter-width / $divisor));
  }
}

// Generate the percent columns
@mixin make-percent-sm-column($percent, $gutter: $grid-md-gutter-width) {
  position: relative;
  min-height: 1px;
  @include make-responsive-col-gutter-width();

  @media (min-width: $screen-sm-min) {
    float: left;
    width: $percent;
  }
}
@mixin make-percent-sm-column-offset($percent) {
  @media (min-width: $screen-sm-min) {
    margin-left: $percent;
  }
}
@mixin make-percent-sm-column-push($percent) {
  @media (min-width: $screen-sm-min) {
    left: $percent;
  }
}
@mixin make-percent-sm-column-pull($percent) {
  @media (min-width: $screen-sm-min) {
    right: $percent;
  }
}

// Generate the percent columns
@mixin make-percent-md-column($percent, $gutter: $grid-md-gutter-width) {
  position: relative;
  min-height: 1px;
  @include make-responsive-col-gutter-width();

  @media (min-width: $screen-md-min) {
    float: left;
    width: $percent;
  }
}
@mixin make-percent-md-column-offset($percent) {
  @media (min-width: $screen-md-min) {
    margin-left: $percent;
  }
}
@mixin make-percent-md-column-push($percent) {
  @media (min-width: $screen-md-min) {
    left: $percent;
  }
}
@mixin make-percent-md-column-pull($percent) {
  @media (min-width: $screen-md-min) {
    right: $percent;
  }
}


// Creates a wrapper for a series of columns
@mixin make-row($gutter: $grid-gutter-width) {
  @include make-responsive-col-gutter-width(-2, margin);
  @include clearfix;
}

// Generate the extra small columns
@mixin make-xs-column($columns, $gutter: $grid-xs-gutter-width) {
  position: relative;
  float: left;
  width: percentage(($columns / $grid-columns));
  min-height: 1px;
  @include make-responsive-col-gutter-width();
}

// Generate the small columns
@mixin make-sm-column($columns, $gutter: $grid-sm-gutter-width) {
  position: relative;
  min-height: 1px;
  @include make-responsive-col-gutter-width();

  @media (min-width: $screen-sm-min) {
    float: left;
    width: percentage(($columns / $grid-columns));
  }
}

// Generate the medium columns
@mixin make-md-column($columns, $gutter: $grid-md-gutter-width) {
  position: relative;
  min-height: 1px;
  @include make-responsive-col-gutter-width();

  @media (min-width: $screen-md-min) {
    float: left;
    width: percentage(($columns / $grid-columns));
  }
}

// Generate the large columns
@mixin make-lg-column($columns, $gutter: $grid-lg-gutter-width) {
  position: relative;
  min-height: 1px;
  @include make-responsive-col-gutter-width();

  @media (min-width: $screen-lg-min) {
    float: left;
    width: percentage(($columns / $grid-columns));
  }
}

@mixin make-grid-columns($i: 1, $list: ".col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}") {
  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}";
  }
  #{$list} {
    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding
    @include make-responsive-col-gutter-width();
  }
}
