// Lists
// -------------------------

// Unordered and Ordered lists
ul,
ol {
  padding-left: 20px;
}

// Helper class
// -------------------------

.text-legend {
  color: $gray;
  font-weight: normal;
  font-size: $font-size-small;
  label & {
    display: block;
    margin-top: 15px;
  }
}
