  .member-card {
    position: relative;
    width: 480px;

    text-align: center;
    font-family: "Helvetica Neue";

    &__dimensions {
      width: 480px;
      height: 317px;
    }

    &__content {
      display: inline-block;
      position: relative;
      top: 135px;
      min-width: 350px;
      padding: 2rem;
    }
    &__footer {
      position: relative;
      top: 145px;
      padding: 2rem;
    }

    &__member {
      display: -webkit-flex;
      display: flex;
      text-align: left;

      &__picture {
        -webkit-flex-shrink: 1;
        flex-shrink: 1;
        height: 65px;
        width: 65px;
        min-width: 65px;
        margin-right: 2rem;

        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.125);
      }

      &__content {
        /* https://css-tricks.com/flexbox-truncated-text */
        min-width: 0;
      }
      &__name {
        color: white;
        font-size: 28px;
        font-weight: 300;
        letter-spacing: 1px;
      }
      &__name--small1 {
        font-size: 24px;
        letter-spacing: 0;
      }
      &__name--small2 {
        font-size: 20px;
      }
      &__name--small3 {
        font-size: 16px;
      }
      &__pseudo {
        color: #fee5a1;
        font-size: 18px;
      }
      &__name,
      &__pseudo {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    &__date {
      color: #fee5a1;
      font-size: 15px;
      font-weight: 300;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    &__date-box {
      display: inline-block;
      padding: 0.25rem 0.75rem;
      background-color: rgba(0, 0, 0, 0.33);
    }

    &__background {
      position: absolute;
      max-width: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      background-color: #bc3b5b;
    }
  }
