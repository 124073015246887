.profile {
  .main {
    @include make-md-column(10);

    .nav-pills {
      margin-bottom: $line-height-computed / 2;
    }
  }

   .list-bulleted {
    li {
      color: $gray;
      font-size: 15px;

      > span {
        color: $gray-dark;
      }
    }
  }

  
}

.shareAlbumTop{
  float: right;
  list-style: none;

  .sicon {
    font-size: 20px;
  }
  
}

.album {
  float: left;
  list-style: none;
  margin:0 15px 10px 0;

  .crudAction{
    float: right;
    margin:0 0 5px 10px;
    font-size: 17px;
    cursor: pointer;
    &:hover,
    &:focus {
      color: $panel-action-hover-color;
    }
  }

  .caption{
    border:none;
    resize:none;
    overflow: hidden;
    padding-left: 5px;
    background: none;
    padding: 0;
    width: 138px;
    height: 40px;
    }
  
}

.album-title {
  margin:1em 0 0.35em 0;
  background-color: #eae8e8;
  
  .caption{
    border:none;
    resize:none;
    overflow: hidden;
    float: left;
    margin-left: 5px;
    background: none;
    padding: 0;
    width: 190px;
    height: 20px;
    }
    
    .crudAction{
      float: right;
      padding:2px 10px 0 0;
      cursor: pointer;
      &:hover,
      &:focus {
        color: $panel-action-hover-color;
      }
    }
}

.list-photos {
  @include list-unstyled;
  padding:0 5px;

  > li {
    height:119px;
    float: left;
    //width: 50%;
    position: relative;

    > a > img {
      @include img-responsive;
    }

    .crudAction{
      font-size:16px;
      cursor: pointer;
      &:hover,
      &:focus {
        color: $panel-action-hover-color;
      }
    }

    .right{
        float: right;
        margin-left:10px;
      }

    .left{
        float: left;
        margin-right:10px;
      }

    &.add-picture,
    &.create-album {
      margin: 0 auto;
      float: initial;
      button {
        color: $gray;
        background-color: transparent;
        display: block;
        position: relative;
        width: 100%;
        padding: 0;
        border: 1px solid $gray-light;

        &:after {
          content: "";
          display: block;
          padding-bottom: 100%;
        }

        .wrapper {
          position: absolute;
          width: 100%;
          display: block;
          text-align: center;
          margin-top: 50%;
          @include translate(0, -50%);

          .plus {
            color: $brand-primary;
            display: block;
            font-size: 26px;
            line-height: 34px;
            font-weight: bold;
          }
        }

        &:hover {
          color: $brand-primary;
          border-color: $brand-primary;
        }
      }
    }

    &.create-album {
      margin-left: 50%;
      @include translate(-50%, 0);
    }
  }
}

.panel-club-gallery{
  .create-album {
    margin-top:23px;
    width: 138px;
    float: left;

      button {
        color: $gray;
        background-color: transparent;
        display: block;
        position: relative;
        width: 100%;
        padding: 0;
        border: 1px solid $gray-light;

        &:after {
          content: "";
          display: block;
          padding-bottom: 100%;
        }

        .wrapper {
          position: absolute;
          width: 100%;
          display: block;
          text-align: center;
          margin-top: 50%;
          @include translate(0, -50%);

          .plus {
            color: $brand-primary;
            display: block;
            font-size: 26px;
            line-height: 34px;
            font-weight: bold;
          }
        }

        &:hover {
          color: $brand-primary;
          border-color: $brand-primary;
        }
      }
  }
}

.panel-profile {

  padding: 15px;

  .panel-profile-images {
    position: relative;
    //border-bottom: 1px solid $panel-default-border;
    @include clearfix;
    //min-height: 262px;
  }

  .panel-profile-images-club {
    /*border-bottom: 1px solid $panel-default-border;*/
    min-height: auto;
  }

  .profile-cover {
    float: left;
    width: 100%;
    background-color: $brand-primary;
  }

  .profile-cover-club {
    width: 100%;
  }

  .panel-heading {
    position: relative;
    padding-left: 41%;
    line-height: 24px;

  .profile-metas {
      &.with-admin {
        float: left;
        width: 90%;
      }

      .profile-state {
        float: right;
      }
    }

    
  }

  .profile-picture {
    position: absolute;
    z-index:1;
    //@include size(142px, 142px);
    //top: 182px;
    top:79%;
    width: 42%;
  }

  .profile-name {
    color: $gray-dark;
    //margin-top: $line-height-computed * 2;
    margin: 0;
  }

  .manage-pictures {
    position: absolute;
    top: 0;
    left: 0;
    @include make-responsive-col-gutter-width(2, "margin");
  }

  .profile-metas {
     color: $gray;
     @include list-unstyled;
     margin-bottom: 0;
     @include clearfix;

     > li {
       float: left;
     }

     .profile-location-icon {
       color: $brand-primary;
       font-size: $font-size-medium;
       vertical-align: -2px;
     }

     .btn-action {
       margin: -7px -5px;
     }
  }

  .panel-body {
    .profile-metas {
      .profile-situation {
        width: 30%;
      }
      .profile-location
      {
        width: 50%;

        span {
          margin-right: $font-size-base / 2;
          margin-left: $font-size-base / 2;
        }
      }
      .profile-state {
        width: 20%;

        span {
          margin-right: $font-size-base / 2;
        }
      }
    }
  }

  .panel-footer {
    @include clearfix();

    .progress {
      margin-bottom: $line-height-computed / 4;
    }
  }

  .more,
  .more > a {
    font-size: $font-size-large;
  }

  #publicActionBtnsDesktop{
    width:100%;
    margin-top: 25px;

    .btn{
      padding-left: 45px;
      padding-right: 45px;
    }
  }

  #publicActionBtnsMobile{
    display: none
  }
  
  .profile-state .mobile,
  .profile-situation .mobile{
      display:none;
    }
}

.panel-private-album {
  min-height: 389px;

  .panel-body {
    color: $gray;
    //padding: 2px;

    p:last-child {
      margin-bottom: 0;
    }
  }

  .album-locked {
    text-align: center;
    padding-top: 5%;
    span {
      font-size: 12 px;
    } 
  }

  .album-locked-icon {
    font-size: $font-size-h1;   
  }

  .list-photos {
    @include clearfix;
    //margin: 4px;

    > li {
      padding: 3px;
    }
  }
}

.panel-profile-recherche,
.panel-profile-disponibilite {
  .panel-body,
  .panel-add-content {
    height: 180px - 51px;
    overflow-y: auto;
  }
}

.panel-profile-type-echange {
  .panel-body,
  .panel-add-content {
    height: 380px - 51px;
    overflow-y: scroll;
  }
}

.panel-profile-parrain {
  min-height: 389px;
}

.panel-profile-description {
  @include clearfix;

  .table {
    float: left;
    position: relative;

    th {
      color: $gray;
      font-weight: normal;
      border-top: none;
    }

    td {
      color: #fff;
      text-align: center;
      width: 50%;
      border-top: none;
      position: relative;
      z-index: 100;
    }

    .person-picture {
      > th {
        padding: 0;
      }

      > td {
        background-size: cover;
        background-position: center;
        position: absolute;
        @include size(50%, 100%);
        top: 0;
        z-index: 0;

        &::before {
          content: "";
          @include size(100%, 100%);
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    &.gender-homme .person-picture > td::before {
      background-color: rgba($brand-info, 0.5);
    }

    &.gender-femme .person-picture > td::before {
      background-color: rgba($brand-primary, 0.4);
    }

    &.solo {
      width: 100%;
    }
  
    &.double {
      width: (100% / 3) * 2;
  
      &:last-child {
        width: (100% / 3);
  
        th {
          display: none;
        }
  
        .person-picture > td {
          width: 100%;
        }
      }
    }

  }
  &.club {
    img {
      width: 100%;
    }
    td {
      color:black;
    }
  }
}

.profile-godson-title {
  margin-top: 0;
  margin-bottom: ($grid-gutter-width * 1.2);
}

.albumThumbnailContainer{
  overflow: hidden;
  height: 119px;
  
  .lightgalleryItem img{
    height: 119px;
    margin: 0 5px;
  }

  .photoSquare{
    display:inline-block;
    width:119px;
    height:119px;
    background-color:#f5f5f5;
    margin:0 5px;
  }
}

@media (max-width: $screen-md-max) {
  .albumThumbnailContainer{
    height: 100px;
    
    .lightgalleryItem img{
      height: 100px;
      margin: 0 3px;
    }

    .photoSquare{
      width:100px;
      height:100px;
      margin:0 3px;
    }
  }

  .panel-profile-recherche,
  .panel-profile-disponibilite {
    min-height: 135px;
  }

  .panel-profile-type-echange {
    min-height: 290px;
  }

  .panel-profile{
    #publicActionBtnsDesktop{
      .btn{
        padding-left: 28px;
        padding-right: 28px;
      }
    }
  }
}

@media (max-width: 767px) {
  .panel-profile{
    #publicActionBtnsDesktop{
      display: none;
    }

    #publicActionBtnsMobile{
      display: block;
      margin-left: 40%;
      
      .btn{
        width: 90%;
        margin:8px;
      }
    }

    .profile-situation .desktop, .profile-state .desktop{
      display:none;
    }

    .profile-situation .mobile, .profile-state .mobile{
      display:block;
    }
    
    .profile-situation .mobile{
      float:right;
    }

    .panel-heading {
      padding-left: 0;
    }

  }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max){
  .albumThumbnailContainer{
    height: 76px;
    
    .lightgalleryItem img{
      height: 76px;
      margin: 0 1px;
    }
    
    .photoSquare{
      width:76px;
      height:76px;
      margin:0 1px;
    }
  }

  .panel-profile{
    #publicActionBtnsDesktop{
      .btn{
        padding-left: 12px;
        padding-right: 12px;
      }
    }
  }
  
}

@media (min-width: 414px) and (max-width: 767px) {
  .albumThumbnailContainer{
    height: 105px;
    
    .lightgalleryItem img{
      height: 105px;
      margin: 0 1px;
    }
    
    .photoSquare{
      width:105px;
      height:105px;
      margin:0 1px;
    }
  }

  .panel-profile{
    #publicActionBtnsDesktop{
      display: none;
    }

  }
}

@media (min-width: 375px) and (max-width: 413px) {
  .albumThumbnailContainer{
    height: 92px;
    
    .lightgalleryItem img{
      height: 92px;
      margin: 0 1px;
    }

    .photoSquare{
      width:92px;
      height:92px;
      margin:0 1px;
    }
  }
}

@media (max-width: 374px) {
  .albumThumbnailContainer{
    height: 87px;
    
    .lightgalleryItem img{
      height: 87px;
      margin: 0 1px;
    }
    
    .photoSquare{
      width:87px;
      height:87px;
      margin:0 1px;
    }
  }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max), (max-width: 375px){
  h2.profile-name{
    font-size:20px
  }
}

.panel-profile-apropos {
  .section {
    margin-bottom: 15px;
  }

  h5 {
    font-size: $font-size-base;
    color: $gray;
  }

  table {
    tbody {
      font-size: 15px;

      h5 {
        font-size: $font-size-base;
        color: $gray;
      }
    }
  }
}

.panel-profile-details {
  .panel-footer {
    cursor: pointer;
  }
}

.modal-images {
  label {
    color: $gray;
    margin-bottom: $line-height-computed;
  }

  .list-photos {
    @include clearfix;

    > li {
      @include clearfix;
      margin-bottom: $grid-gutter-width / 2;
      margin-right: 15px;

      > img {
        float: left;
        margin-right: 3px;
      }
    }

    .btn-delete {
      font-size: $font-size-small;
      color: $gray;
    }
  }

  .photo-actions {
    @include list-unstyled();
    float: left;

    > li {
      a {
        cursor: pointer;
        font-size: $font-size-small;
      }
    }
  }
}

.profile-panel-travel {
  display: flex;
  justify-content: center;
  padding: 7px 0 5px 5px!important;
  border: 1px solid #ee5767;
  margin-top: 10px;
  border-radius: 7px;
  box-shadow: 0 .125em .5em rgba(0,0,0,.2);

  &__icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 1.5rem;
    background-image: url(../images/travel_plane.png);
    background-size: 60px 21px;
    width: 60px;
    height: 21px;
  }

  &__label {
    font-size: 15px;
    color: #AAA;
    text-transform: uppercase;
  }

  &__description {
    margin-top: 4px;
    font-size: 15px;
    font-weight: bold;
    color: #ee5767;
  }
}

.profile-panel-event {
  
  padding: 7px 5px 5px 5px!important;
  border: 1px solid #ee5767;
  margin-top: 10px;
  border-radius: 7px;
  box-shadow: 0 .125em .5em rgba(0,0,0,.2);

  .eventImg {
    float: left;
    width: 67px;
    margin-right: 5px;
  }

  .eventRightPart{
    text-align: center;
  }
  
  .attendLabel {
    font-size: 15px;
    color: #AAA;
  }

  .title {
    margin: 8px 0 8px 0;
    font-size: 15px;
    font-weight: bold;
    color: #ee5767;
  }
}

.profile-panel-travel-promo {
  display: flex;
  align-items: center;

  &__content {
    flex-grow: 1;
  }
}

.profile-reviews {
  &__review {
    margin-bottom: 3rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.profile-membercard {
  &-action {
    float: right;
    margin-left: 1rem;
  }

  &-content {
    margin: 3rem 0;
    text-align: center;
  }

  &-image {
    position: relative;
    top: 0;
    width: 400px;
    max-width: 100%;

    border-radius: 3px;
    box-shadow: 0 .25rem 2rem rgba(0, 0, 0, .25);
    transition: all .2s;

    &:hover {
      top: -4px;
      box-shadow: 0 1rem 6rem rgba(0,0,0,.33);
    }
  }

  &-gift-panel {
    clear: both;

    &__icon {
      float: left;
      margin-right: 1.5rem;
      color: $brand-primary;
      font-size: 24px;
    }
  }
}
