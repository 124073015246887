//
// Navs
// --------------------------------------------------


// Base class
// --------------------------------------------------

.nav {
  > li {
    > a {
      &:hover,
      &:focus {
        background-color: transparent;
      }
    }
  }
}

// Pills
// -------------------------
.nav-pills {
  margin-left: -$grid-gutter-width / 2;
  margin-right: -$grid-gutter-width / 2;
  margin-bottom: $line-height-computed;
  > li {
    > a {
      color: $nav-pills-active-link-color;
    }

    // Active state
    &.active > a {
      &,
      &:hover,
      &:focus {
        .label {
          color: $nav-pills-active-link-hover-color;
          border-color: $nav-pills-active-link-hover-color;
        }
      }
    }
  }

  .filters-right {
    float: right;
    margin-right: $grid-gutter-width / 2;
  }
}

// Nesting Button broups

.nav-pills {
  > .btn-link {
    color: $nav-pills-active-link-color;
    padding: 10px 15px;

    &.active,
    &:hover,
    &:focus {
      text-decoration: none;
      color: $nav-pills-active-link-hover-color;
    }
  }
}
