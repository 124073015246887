//
// Dropdown menus
// --------------------------------------------------

.dropdown {
  .btn-link {
    //color: $gray;
    &:hover,
    &:focus {
      color: $brand-primary;
      text-decoration: none;
      outline: none;
    }
  }
}

// Dropdown arrow/caret
.caret {
  display: inline;
  border: none;
  @include make-sicon( $sicon-chevron-down );
}


// The dropdown menu (ul)
.dropdown-menu {
  padding: 0;
  border: none;
  @include box-shadow(0 1px 2px rgba(0,0,0,.10));


  > li > a {
    padding: 8px 12px;
  }
  > li {
    &:hover {
      cursor: pointer;
    };
    padding: 2px;
  }
}

// Small variant

.dropdown-menu-sm {
  > li > a {
    padding: 3px 12px;
  }
}


// Dropdown section headers
.dropdown-header {
  padding: 6px 12px;
  border-bottom: 1px solid #f2f2f2;

  .dropdown-action {
    float: right;

    > a {
      color: $gray;
      font-size: $font-size-small;
      text-decoration: none;

      &:hover,
      &:focus {
        color: darken($gray, 5%);
      }

      &:focus {
        @include tab-focus;
      }
    }
  }
}

// Dropdown section footer
.dropdown-footer {
  display: block;
  //font-size: $font-size-small;
  line-height: $line-height-base;
  text-align: center;
}

.dropdown-menu {
  .dropdown-footer {
    > a {
      color: $dropdown-header-color;
      display: block;
      padding: 12px 12px;
    }
  }
}

// Dropdown for emoticons

.dropdown-emoticons {
  width: 180px;
  height: 200px;
  padding: 10px;
  overflow-y: scroll;
  @include clearfix;

  > li {
    float: left;
    margin: 5px;
  }
}
