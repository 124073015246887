.sw-referral-code-card {
    position: relative;
    margin: 1rem;

    &::before {
        display: block;
        padding-top: (10.5 / 16 * 100%);
        content: '';
    }

    &__card, &__overlay, &__bottom-bar {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    &__card, &__overlay {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__overlay {
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 3px;
    }
    &__card {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 1rem 1.5rem;

        border-radius: 3px;
        background: $sw-gradient-primary;
        box-shadow: 0 .75rem 1.25rem rgba(0, 0, 0, .25);

        text-align: center;

        &.is-disabled {
            pointer-events: none;
            opacity: .5;
        }
    }

    &__code {
        width: 100%;
        padding: .5rem .75rem;
        background-color: rgba(0, 0, 0, .2);

        color: #fee5a1;
        font-size: 16px;
        font-weight: 100;
        letter-spacing: 1.5px;
    }

    &__bottom-bar {
        top: auto;
        padding: .75rem;
        text-align: center;
    }

    &__bottom-label, &__bottom-button {
        display: inline-block;
        vertical-align: middle;
        max-width: 100%;
        padding: .5rem .75rem;
        border-radius: 2px;
        background-color: rgba(0, 0, 0, .5);

        color: white;
        font-size: 10px;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    &__bottom-button {
        float: right;
        margin-left: .5rem;
        border: 0;
        background-color: rgba(0, 0, 0, .25);
        cursor: pointer;

        &:hover { background-color: rgba(0, 0, 0, .5); }
        &:active { background-color: rgba(0, 0, 0, .375); }
    }

    &--visible-on-hover {
        display: none;
    }
    &:hover &--visible-on-hover {
        display: block;
    }
}
