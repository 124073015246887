/*
 * Circular Pro Font
 *
 * Legal Disclaimer
 *
 * These Fonts are licensed only for use on these domains and their subdomains:
 * swingsy.net
 *
 * It is illegal to download or use them on other websites.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Lineto.com, 2016
 */

$sw-font-base: '../fonts/' !default;
$sw-font-formats: (eot, woff, ttf, svg) !default;

@mixin make-font(
  $family,
  $filename,
  $weight: normal,
  $style: normal,
  $svg-id: null,
  $suffix: '',
  $formats: $sw-font-formats,
  $base: $sw-font-base
) {
  $filename: #{$base}#{$filename};
  $svg-id: if($svg-id != null, $svg-id, $filename);
  $psuffix: if(str-length($suffix) > 0, '?#{$suffix}', '');

  @font-face {
    font-family: $family;
    src: if(index($formats, eot),   url('#{$filename}.eot#{$psuffix}'),                                          null);
    src: if(index($formats, eot),   url('#{$filename}.eot?#{$suffix}#iefix')       format('embedded-opentype'),  null),
         if(index($formats, woff2), url('#{$filename}.woff2#{$psuffix}')           format('woff2'),              null),
         if(index($formats, woff),  url('#{$filename}.woff#{$psuffix}')            format('woff'),               null),
         if(index($formats, ttf),   url('#{$filename}.ttf#{$psuffix}')             format('truetype'),           null),
         if(index($formats, svg),   url('#{$filename}.svg#{$psuffix}##{$svg-id}')  format('svg'),                null);
    font-weight: $weight;
    font-style: $style;
  }
}

$sw-font-circular-formats: (eot, woff, woff2);
@include make-font('Circular', 'lineto-circular-pro-book',          $formats: $sw-font-circular-formats);
@include make-font('Circular', 'lineto-circular-pro-medium', bold,  $formats: $sw-font-circular-formats);

@include make-font('Helvetica Neue', 'HelveticaNeue');
@include make-font('Helvetica Neue', 'HelveticaNeue-Light', 300);
@include make-font('Helvetica Neue', 'HelveticaNeue-UltraLight', 100);

//@include make-font('logoRegular', 'OPTICopperplate');

@include make-font('GothamLight', 'GothamLight');

/*
 * Swingsy Icons Font
 */

@include make-font('swingsicons', 'swingsicons', $suffix: '9bygsc');

$sicon-add-1: "\e900";
$sicon-alarm: "\e901";
// $sicon-arrow-down-12: "\e902";
// $sicon-arrow-left-12: "\e903";
// $sicon-arrow-right-12: "\e904";
// $sicon-arrow-up-12: "\e905";
$sicon-bubble-chat-1: "\e906";
$sicon-camera-2: "\e907";
$sicon-check-circle-2: "\e908";
$sicon-clock-2: "\e909";
$sicon-close: "\e90a";
$sicon-dots: "\e90b";
$sicon-email-send-1: "\e90c";
$sicon-gender-female: "\2640";
$sicon-gender-male: "\2642";
$sicon-gender-trans: "\26a6";
$sicon-heart: "\e90f";
$sicon-heart-fill: "\e932";
$sicon-heart-combined: "\e90d";
$sicon-information-circle: "\e910";
$sicon-link-3: "\e911";
$sicon-location-pin-target-2: "\e912";
$sicon-lock-close-2: "\e913";
$sicon-lock-close-4: "\e914";
$sicon-lock-open-2: "\e915";
$sicon-pencil-2: "\e916";
$sicon-pencil-write-2: "\e917";
$sicon-present-box: "\e918";
$sicon-report-problem-circle: "\e919";
$sicon-rocket: "\e91a";
$sicon-search: "\e91b";
$sicon-sketch-pad: "\e91c";
$sicon-smiley-smile-2: "\e91d";
$sicon-subtract-1: "\e91e";
$sicon-synchronize-3-down: "\e91f";
$sicon-synchronize-3-up: "\e920";
$sicon-synchronize-3: "\e921";
$sicon-temperature-medium: "\e922";
$sicon-trash: "\e916";
$sicon-view-2: "\e923";
$sicon-bdsm-m: "\e924";
$sicon-bdsm-f: "\e925";
$sicon-duo-1: "\e926";
$sicon-duo-2: "\e927";
$sicon-gang-bang-f: "\e928";
$sicon-gang-bang-m: "\e929";
$sicon-plurality-f: "\e92a";
$sicon-plurality-m: "\e92b";
$sicon-trio-1-f: "\e92c";
$sicon-trio-1-m: "\e92d";
$sicon-trio-2-m: "\e92e";
$sicon-single: "\e92f";
$sicon-swingers-f: "\e930";
$sicon-swingers-m: "\e931";
$sicon-chevron-down: "\e933";
$sicon-chevron-left: "\e934";
$sicon-chevron-right: "\e935";
$sicon-chevron-up: "\e936";
//icon arrow to chevron
$sicon-arrow-down-12: "\e933";
$sicon-arrow-left-12: "\e934";
$sicon-arrow-right-12: "\e935";
$sicon-arrow-up-12: "\e936";
$sicon-add-friend: "\e937";
$sicon-events: "\e938";
$sicon-heartbeat: "\e939";
$sicon-search-people: "\e940";
$sicon-news: "\e941";

@mixin make-sicon($sicon) {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'swingsicons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &::before {
    content: $sicon;
  }
}

@mixin make-stackicon() {
  display: inline-block;
 vertical-align: top;
 white-space: nowrap;
 /* child elements absolute */
 position: relative;
 /* remove inline-block white-space */
 margin-right: -.16em; /* 5px */
 /* if not already universally applied */
 -moz-box-sizing: border-box;
 -webkit-box-sizing: border-box;
 box-sizing: border-box;
 /* padding here for text, icons replicate this using size and position:absolute - padding makes touch-target bigger */
 padding: 0.143em;
 /* units used in font: 1em = 2048, icons 2400 wide, so icons are 1.171875em (2400/2048). Add padding x2 to get size: */
 height: 1.45788em;
 width: 1.45788em;
 font-size: 1.815em;
 /* text hidden old-school */
 text-align: left;
 text-indent: -9999px;

  &::before,
  &::after {
    display: block;
    position: absolute;
    white-space: normal;
    /* match padding above */
    top: 0.143em;
    left: 0.143em;
    /* undo text hidden */
    text-indent: 0;
    /* inherits size from parent, ems cascade */
    font-size: 1em;
    // font-family: "Stackicons-Social";
    font-weight: 400 !important;
    font-style: normal !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    /* screenreaders */
    speak: none;
    /* transitions here */
  }
}

.sicon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'swingsicons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sicon-add-1 {
  @include make-sicon( $sicon-add-1 );
}
.sicon-alarm {
  @include make-sicon( $sicon-alarm );
}
.sicon-arrow-down-12 {
  @include make-sicon( $sicon-arrow-down-12 );
}
.sicon-arrow-left-12 {
  @include make-sicon( $sicon-arrow-left-12 );
}
.sicon-arrow-right-12 {
  @include make-sicon( $sicon-arrow-right-12 );
}
.sicon-arrow-up-12 {
  @include make-sicon( $sicon-arrow-up-12 );
}
.sicon-bubble-chat-1 {
  @include make-sicon( $sicon-bubble-chat-1 );
}
.sicon-camera-2 {
  @include make-sicon( $sicon-camera-2 );
}
.sicon-check-circle-2 {
  @include make-sicon( $sicon-check-circle-2 );
}
.sicon-clock-2 {
  @include make-sicon( $sicon-clock-2 );
}
.sicon-close {
  @include make-sicon( $sicon-close );
}
.sicon-dots {
  @include make-sicon( $sicon-dots );
}
.sicon-email-send-1 {
  @include make-sicon( $sicon-email-send-1 );
}
.sicon-gender-female {
  @include make-sicon( $sicon-gender-female );
}
.sicon-gender-male {
  @include make-sicon( $sicon-gender-male );
}
.sicon-gender-trans {
  @include make-sicon( $sicon-gender-trans );
}
.sicon-heart {
  @include make-sicon( $sicon-heart );
}
.sicon-heart-fill {
  @include make-sicon( $sicon-heart-fill );
}
.sicon-heart-combined {
  @include make-sicon( $sicon-heart-combined );
}
.sicon-information-circle {
  @include make-sicon( $sicon-information-circle );
}
.sicon-link-3 {
  @include make-sicon( $sicon-link-3 );
}
.sicon-location-pin-target-2 {
  @include make-sicon( $sicon-location-pin-target-2 );
}
.sicon-lock-close-2 {
  @include make-sicon( $sicon-lock-close-2 );
}
.sicon-lock-close-4 {
  @include make-sicon( $sicon-lock-close-4 );
}
.sicon-lock-open-2 {
  @include make-sicon( $sicon-lock-open-2 );
}
.sicon-pencil-2 {
  @include make-sicon( $sicon-pencil-2 );
}
.sicon-pencil-write-2 {
  @include make-sicon( $sicon-pencil-write-2 );
}
.sicon-present-box {
  @include make-sicon( $sicon-present-box );
}
.sicon-report-problem-circle {
  @include make-sicon( $sicon-report-problem-circle );
}
.sicon-rocket {
  @include make-sicon( $sicon-rocket );
}
.sicon-search {
  @include make-sicon( $sicon-search );
}
.sicon-sketch-pad {
  @include make-sicon( $sicon-sketch-pad );
}
.sicon-smiley-smile-2 {
  @include make-sicon( $sicon-smiley-smile-2 );
}
.sicon-subtract-1 {
  @include make-sicon( $sicon-subtract-1 );
}
.sicon-synchronize-3-down {
  @include make-sicon( $sicon-synchronize-3-down );
}
.sicon-synchronize-3-up {
  @include make-sicon( $sicon-synchronize-3-up );
}
.sicon-synchronize-3 {
  @include make-sicon( $sicon-synchronize-3 );
}
.sicon-temperature-medium {
  @include make-sicon( $sicon-temperature-medium );
}
.sicon-view-2 {
  @include make-sicon( $sicon-view-2 );
}
.sicon-bdsm-m {
  @include make-sicon( $sicon-bdsm-m );
}
.sicon-bdsm-f {
  @include make-sicon( $sicon-bdsm-f );
}
.sicon-duo-1 {
  @include make-sicon( $sicon-duo-1 );
}
.sicon-duo-2 {
  @include make-sicon( $sicon-duo-2 );
}
.sicon-gang-bang-f {
  @include make-sicon( $sicon-gang-bang-f );
}
.sicon-gang-bang-m {
  @include make-sicon( $sicon-gang-bang-m );
}
.sicon-plurality-f {
  @include make-sicon( $sicon-plurality-f );
}
.sicon-plurality-m {
  @include make-sicon( $sicon-plurality-m );
}
.sicon-trio-1-f {
  @include make-sicon( $sicon-trio-1-f );
}
.sicon-trio-1-m {
  @include make-sicon( $sicon-trio-1-m );
}
.sicon-trio-2-m {
  @include make-sicon( $sicon-trio-2-m );
}
.sicon-single {
  @include make-sicon( $sicon-single );
}
.sicon-swingers-f {
  @include make-sicon( $sicon-swingers-f );
}
.sicon-swingers-m {
  @include make-sicon( $sicon-swingers-m );
}
.sicon-chevron-small-down {
  @include make-sicon( $sicon-chevron-down );
}
.sicon-chevron-small-left {
  @include make-sicon( $sicon-chevron-left );
}
.sicon-chevron-small-right {
  @include make-sicon( $sicon-chevron-right );
}
.sicon-chevron-small-up {
  @include make-sicon( $sicon-chevron-up );
}
.sicon-trash {
  @include make-sicon( $sicon-trash );
}
.sicon-add-friend {
  @include make-sicon( $sicon-add-friend );
}
.sicon-events {
  @include make-sicon( $sicon-events );
}
.sicon-heartbeat {
  @include make-sicon( $sicon-heartbeat );
}
.sicon-search-people {
  @include make-sicon( $sicon-search-people );
}
.sicon-news {
  @include make-sicon( $sicon-news );
}


// Custom icons

.sicon-homme,
.sicon-gay,
.sicon-homme-hetero {
  @include make-sicon( $sicon-gender-male );
  color: $brand-info;
}
.sicon-femme,
.sicon-lesbienne,
.sicon-femme-hetero {
  @include make-sicon( $sicon-gender-female );
  color: $brand-primary;
}
.sicon-homme-bi {
  @include make-sicon( $sicon-gender-male );
  color: $brand-success;
}
.sicon-femme-bi {
  @include make-sicon( $sicon-gender-female );
  color: $brand-success;
}
.sicon-trans--trav {
  @include make-sicon( $sicon-gender-trans );
  color: $brand-warning;
}
.sicon-autre {
  @include make-sicon( $sicon-single );
}

// Stack icons

.sicon-couple-hetero,
.sicon-couple-hetero-bi,
.sicon-couple-hetero-f-bi,
.sicon-couple-hetero-h-bi {
  &::before {
    content: $sicon-gender-female;
    color: $brand-primary;
  }
  &::after {
    content: $sicon-gender-male;
    color: $brand-info;
  }
}
.sicon-couple-hetero-f-bi,
.sicon-couple-hetero-bi {
  &::before {
    color: $brand-success;
  }
}
.sicon-couple-hetero-h-bi,
.sicon-couple-hetero-bi {
  &::after {
    color: $brand-success;
  }
}
.sicon-couple-gay {
  &::before,
  &::after {
    content: $sicon-gender-male;
    color: $brand-info;
  }
}
.sicon-couple-lesbienne {
  &::before,
  &::after {
    content: $sicon-gender-female;
    color: $brand-primary;
  }
}

.sicon-gang-bang {
  @include make-stackicon;
  font-size: 1.2em;
  &::before {
    content: $sicon-gang-bang-m;
    color: $brand-info;
  }
  &::after {
    content: $sicon-gang-bang-f;
    color: $brand-primary;
    left: 0.556em;
  }
}

.sicon-bdsm {
  font-size: 1.2em;
  &::before {
    content: $sicon-bdsm-m;
    color: $brand-info;
  }
  &::after {
    content: $sicon-bdsm-f;
    color: $brand-primary;
  }
}

.sicon-echangiste {
  @include make-stackicon;
  font-size: 1.2em;
  &::before {
    content: $sicon-swingers-m;
    color: $brand-info;
    left: 0.519em;
  }
  &::after {
    content: $sicon-swingers-f;
    color: $brand-primary;
  }
}

.sicon-pluralite {
  @include make-stackicon;
  font-size: 1.2em;
  &::before {
    content: $sicon-plurality-f;
    color: $brand-primary;
    left: 0.519em;
  }
  &::after {
    content: $sicon-plurality-m;
    color: $brand-info;
  }
}

.sicon-trio {
  @include make-stackicon;
  font-size: 1.2em;
  &::before {
    content: $sicon-trio-1-f;
    color: $brand-primary;
  }
  &::after {
    content: $sicon-trio-1-m;
    color: $brand-info;

    left: 0.31em;
  }
}

.sicon-invitation {
  @include make-stackicon;
  // position: relative;
  font-size: 1em;

  &::before {
    content: $sicon-synchronize-3-down;
    color: $brand-primary;
  }
  &::after {
    content: $sicon-synchronize-3-up;
    color: $brand-info;
    margin-top: 0.35em;
    left: .35em;
  }
}
