//
// Progress bars
// --------------------------------------------------

// Bar itself
// -------------------------

// Outer container
.progress {
  height: $font-size-small;
  background-color: $progress-bg;
  box-shadow: none;
}

.progress-bar {
  line-height: $font-size-small;
}
