@mixin button-outline-variant($color) {
  color: $color;
  background-image: none;
  background-color: transparent;
  border-color: $color;

  &:hover,
  &:focus {
    @if (lightness($color) > 75) { // if color is "too white", then use $brand as text color
      color: $brand-primary;
    }
    @else {
      color: #fff;
    }
    background-color: $color;
        border-color: $color;
  }

  &:focus,
  &.focus {
    @if (lightness($color) > 75) {
      color: $brand-primary;
    }
    @else {
      color: #fff;
    }
    background-color: $color;
        border-color: $color;
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    @if (lightness($color) > 75) {
      color: $brand-primary;
    }
    @else {
      color: #fff;
    }
    background-color: $color;
        border-color: $color;

    &:hover,
    &:focus,
    &.focus {
      color: #fff;
      background-color: darken($color, 17%);
          border-color: darken($color, 25%);
    }
  }

  &.disabled,
  &:disabled {
    &:focus,
    &.focus {
      border-color: lighten($color, 20%);
    }
    &:hover,
    &:focus {
      border-color: lighten($color, 20%);
    }
  }
}

@mixin button-underline-variant($color, $border, $alt) {
  color: $color;
  background-image: none;
  background-color: transparent;
  border: none;
  border-bottom: $border solid 2px;

  &:hover,
  &:focus {
    color: $alt;
    border-color: $alt;
  }

  &:focus,
  &.focus {
    color: $alt;
    border-color: $alt;
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $alt;
    border-color: $alt;
    box-shadow: none;

    &:hover,
    &:focus,
    &.focus {
      color: darken($alt, 17%);
      border-color: darken($alt, 17%);
    }
  }

  &.disabled,
  &:disabled {
    &:focus,
    &.focus {
      border-color: lighten($color, 20%);
    }
    &:hover,
    &:focus {
      border-color: lighten($color, 20%);
    }
  }
}
