//
// Pagination (multiple pages)
// --------------------------------------------------
.pagination {
  > li {
    > a,
    > span {
      padding: $padding-base-vertical $padding-base-horizontal / 2;
    }
  }
}
