// pane without border on nav
.nav-tabs {
    border-bottom: transparent;
}
.nav-tabs>li>a {
  color: $gray;
}

.nav-tabs>li>a:hover,
.nav-tabs>li>a:focus {
  color: $gray-dark;
  background-color: transparent;
  border: 1px solid transparent;
}
.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
    color: $brand-primary;
    background-color: transparent;
    border: 1px solid transparent;
    border-bottom-color: transparent;
    cursor: default;
}
.tab-content {}
