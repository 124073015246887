@mixin make-vertical-align($position: relative) {
  position: $position;
  top: 50%;
  // transform: perspective(1px) translateY(-50%);
  @include translate(0, -50%);
}

@mixin make-horizontal-align($position: relative) {
  position: $position;
  left: 50%;
  // transform: perspective(1px) translateX(-50%);
  @include translate(-50%, 0);
}

@mixin make-full-align($position: relative) {
  position: $position;
  top: 50%;
  left: 50%;
  // transform: perspective(1px) translate(-50%,-50%);
  @include translate(-50%, -50%);
}
