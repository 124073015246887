.alert {
  position: relative;
  margin-top: 62px;
  @include box-shadow(0 1px 2px 0 rgba(0,0,0,.1));

  .strong {
    color: $gray-dark;
  }
}

.alert-title {
  color: $gray-dark;
  font-weight: bold;
  font-size: 16px;
  display: block;
}

.alert-edge {
  color: #fff;
  font-size: $font-size-h3;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: percentage((1 / $grid-columns));

  .sicon,
  .glyphicon {
    position: absolute;
    top: 50%;
    @include translate(-50%, -50%);
  }
}

.alert-body {
  color: $gray;
  width: percentage((9 / $grid-columns));
  margin-left: percentage((1 / $grid-columns));
}

.alert-dismissable, // The misspelled .alert-dismissable was deprecated in 3.2.0.
.alert-dismissible {
  padding-right: ($alert-padding + 20);

  // Adjust close link position
  .close {
    color: $gray-dark;
    top: -2px;
    right: -21px;
    color: inherit;
    font-size: $font-size-small;
  }
}

.alert-warning .alert-edge {
  background-color: $state-warning-border;
}
.alert-success .alert-edge {
  background-color: $state-success-border;
}
.alert-info .alert-edge {
  background-color: $state-info-border;
}
.alert-danger .alert-edge {
  background-color: $state-danger-border;
}
