//
// Panels
// --------------------------------------------------


.panel-default {
  border: none;
  /*border: 2px solid gray;*/
}

.panel-action {
  float: right;
  // margin-left: 10px;
  margin-right: -5px;

  > a {
    //color: $panel-action-color;
    text-decoration: none;

    &:hover,
    &:focus {
      color: $panel-action-hover-color;
    }

    &:focus {
      @include tab-focus;
      outline: none;
    }
  }
}

.panel-add-content {
  position: relative;
  min-height: 130px;

  > a,
  > button {
    @include make-full-align(absolute);
    color: $gray;
    background-color: transparent;
    display: block;
    border: none;

    .plus {
      color: $brand-primary;
      display: block;
      font-size: 34px;
      line-height: 34px;
      font-weight: bold;
    }
    &:hover {
      color: $brand-primary;
      border-color: $brand-primary;
    }
  }
}

// Optional footer (stays gray in every modifier class)
.panel-footer {
  color: $panel-default-text;

  .action{
    color: $brand-primary;
    cursor: pointer;
  }
}

// Nesting navbars
.panel-default {
  .navbar-default {
    background-color: $panel-default-heading-bg;
    border: none;
    border-bottom: 1px solid $panel-default-border;
    margin-bottom: 0;
  }
}

// Form nesting
.form-horizontal {
  .form-group:first-child {
    &::before {
      border: none;
    }
  }
}
