//
// Input groups
// --------------------------------------------------


// Text input groups
// -------------------------
.input-group-addon {
  border: none;
  border-bottom: 2px solid $input-group-addon-border-color;
  transition: border-color .15s ease-in-out;
  color: $brand-primary;
}

// make border of input addon AFTER the input change when focus is on input
input:focus {
  + .input-group-addon {
    border-bottom-color: $brand-primary;
  }
}
