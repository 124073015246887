div[ui-view="header"] {
  position: fixed;
  z-index: 1000;
  width: 100%;
}

.wrap.container {
  position:relative;
  //background-color: cadetblue;
  @media (max-width: 1200px) {
            
    padding: 0;
  }
  

}
#main-navbar {
  .navbar-brand {
    @include img-retina("../images/logo-swingsy.png", "../images/logo-swingsy@2x.png", 250px, 41px);
    background-repeat: no-repeat;
    background-position: left center;
    width: 250px;
    background-size: contain;
    
    @include text-hide();
    
    @media (max-width: 390px) {
            
      margin-left: 10px;
      @include img-retina("../images/logo-small.png?", "../images/logo-small@2x.png?", 200px, 33px);
      width:200px;
    }
  }

   .nav > li > a {
    padding-left: 10px;
    padding-right: 10px;
    
    @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
      .navLabel{display:none}
      }
  }
  
  .menu-search {
    //@include make-sm-column(3);
    //@include make-md-column(4);
    @media (min-width: $screen-sm-min) {
      width: 150px;
    }
    @media (min-width: $screen-sm-min) {
      margin-left: 30px;
    }
    .menu-search-dropdown {
      padding: 15px 30px 15px;
      @media (min-width: $screen-sm-min) {
        min-width: 400px;
      }
      .form-group {
        padding-top: 15px;
        margin-bottom: 15px;
      }
      .form-group:first-child:before {
        border-top: 0;
      }
      .control-label {
        color: $gray;
        font-weight: 400;
      }
      p.age-label {
        font-size: $font-size-small;
        margin-bottom: -5px;
      }
      p.age-label-her { color: $brand-primary;}
      p.age-label-him { color: $brand-secondary; margin-top: 10px; }
      .dropdown-footer {
        padding: 15px 30px;
        margin: 30px -30px -15px;
        background: $gray-lighter;
        text-align: left;
      }
    }
  }

  .menu-item-search {
    @media (min-width: $screen-sm-min) {
      width: 150px;
    }
    a {
      display: block;
      border-bottom: 2px solid #fff;
      padding-bottom: 3px;
      padding-left: 0px;
      margin-bottom: 10px;

      .caret {
        float: right;
      }
    }

    >a:hover, >a:active, >a:focus,
    &.open>a, &.open>a:focus, &.open>a:hover {
      color: #fff;
    }

    > .dropdown-menu {
      width: 100%;
      margin-top: -13px;
    }
  }

  .menu-item-profil {
    > a {
      padding-top: ( ( $navbar-height - $navbar-avatar-width ) / 2 );
      padding-bottom: ( ( $navbar-height - $navbar-avatar-width ) / 2 );

      > .profile-picture {
        @include size($navbar-avatar-width, $navbar-avatar-width);
        border: none;
      }
    }
  }

  .navbar-nav > li > a > .sicon {
    font-size: 18px;
    line-height: 14px;
  }


  // notification badge count
  .count-badge {
    position: absolute;
    right: 0;
    min-width: 18px;
    text-align: center;
    top: 10px;
    font-size: 10px;
    padding: 3px 5px;
    background: $brand-primary;
    border: 1px solid white;
    font-weight: normal;
  }

  .menu-msg,
  .menu-notification,
  .menu-visits {
    width: 310px;
    margin-top: -5px;

    &:before {
      content: '';
      border: 8px solid transparent;
      border-bottom: 8px solid white;
      position: absolute;
      top: -16px;
      right: 16px;
    }

    > li {
      border-bottom: 1px solid #f2f2f2;
      color: $gray-dark;
    }

    // overwrite color
    > li > a {
      color: $gray-dark;
    }

    .menu-msg-entry,
    .menu-notification-entry,
    .menu-visits-entry {
      position: relative;
    }

    .menu-notification-entry,
    .menu-visits-entry > a,
    .menu-msg-entry > a {
      padding: 10px 10px 25px;
      white-space: inherit;
    }

    .menu-msg-author,
    .menu-msg-excerpt,
    .menu-visits-author,
    .menu-visits-date,
    .menu-notification-excerpt {
      display: block;
      padding-left: 50px;
    }

    .menu-visits-date {
      font-size: $font-size-small;
      .menu-visits-date-time,
      .menu-visits-date-separator {
        color: $gray;
      }
    }

    .menu-notification-excerpt ~ ul {
      display: block;
      padding-left: 50px;
      margin-top: 5px;
      //font-size: $font-size-small;
      li {
        display: inline-block;
        margin-right: 13px;
      }
    }

    .menu-msg-excerpt,
    .menu-notification-excerpt {
      color: $gray;
      /*font-size: $font-size-small;*/
    }

    .menu-msg-avatar {
      position: absolute;
      left: 13px;
      top: 8px;
      width: 40px;
      height: 40px;
      border-radius: 40px;
    }

    .notification-icon {
      position: absolute;
      left: 13px;
      top: 15px;
      font-size:30px;
      color: $brand-primary;
    }
  }


  @media (max-width: $screen-xs-max) {
    .navbar-nav {
      margin: 0 0 9px;
      padding-bottom: 9px;
      text-align: center;
      vertical-align: middle;
      display: block;
      position: relative;
    }
    .navbar-nav > li {
      //display: inline-block;
      vertical-align: middle;
      &.dropdown {
        a {
          padding-top: 8px;
          padding-bottom: 8px;
        }
      }
    }
    .navbar-nav > li > a > .sicon {
      padding: 11px 8px;
      width: 40px;
      height: 40px;
      position: relative;
      //border: 1px solid white;
      vertical-align: middle;
      display: inline-block;
          border-radius: 2px;
    }

    .navbar-nav > .divider-vertical {
      display: none;
    }
    .menu-search {
      margin-bottom: 10px;
      padding: 10px 15px;
      border-bottom: 1px solid white;
      .dropdown-menu {
        background: white;
      }
    }
    .menu-item-profil {
      img {
        margin-left: auto; margin-right: auto;
      }
      .dropdown-menu {
        display: block;
        background: transparent;
        position: relative;
        box-shadow: none;
        text-align: center;
        width: 100%;
        .divider {
          background-color: $link-hover-color;
        }
        a {
           color: white;
            &:hover, &:focus {
              color: $link-hover-color;
              background-color: transparent;
            }
        }
      }
    }

    .navbar-collapse {
      padding: 0;
      border-color: white;
    }

    .sidebar-xs {
      & > .navbar-nav {
        border-bottom: 1px solid white;
        padding-bottom: 15px;
        font-size: 2rem;
        & > li {
          display: block;
        }
      }
        .proposing {
          color: white;
          text-align: center;
          margin: 0;
          padding: 15px 0;
          a {
            color: white;
          }
          a:hover {
            color: $link-hover-color;
          }
        }
        .travel {
          text-align: center;
          padding: 15px 0;
          margin-bottom: 15px;
          border-bottom: 1px solid white;
        }
      .profile-shortcut {
        .navbar-nav {
          padding: 0;
          border-top: 1px solid white;
          border: none;
        }
        li {
          width: 50%;
          float: left;
        }
      }
    }
  }
}

#mainView{
  margin-top:56px;
}
