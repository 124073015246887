#topPinnedContainer{
  position:relative;
  padding: 25px 35px 50px 20%;
  
  #topPinnedProfilePic{
    position:absolute;
    top:5%;
    left:35px
  }

  #topPinnedLeftBg{
    position:absolute;
    left:0;
    bottom:0
  }

  @media screen and (max-width: $screen-sm-max) {
    padding-left: 25%;
  }

  @media screen and (max-width: $screen-xs-max) {
    #topPinnedProfilePic{
      width:70px;
    }
    
  }
  
  @media screen and (max-width: 550px) {
    padding-left: 35%;
    
    #topPinnedProfilePic{
      width:70px;
      top:13%;
    }
  }

  @media screen and (max-width: 390px) {
    padding-left: 35%;
    padding-bottom: 60px;
    
    #topPinnedProfilePic{
      width:70px;
      top:13%;
      left:5%;
    }

    #topPinnedLeftBg{
     width:100%;
    }
  }
}


.post-container{
  margin-bottom: 15px;
}

.post-tile {
  margin-bottom: 16px;
  .card .media {
    border: none;
  }
}

.postHeader{
  min-height: 55px;
  margin-bottom: 10px;
}

.postDate{
  margin-bottom: 5px;
}

.postReview{
  border:1px solid #dadada;
  margin:5px 0 0 75px;
  padding:5px;
}
/** = =====
 flexbox ==== */

.media-body {
  flex: 1;
}

.media-heading {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.media-object.avatar {
  max-width: 55px;
  float: left;
  margin-right: 20px;

}

.media,
.media--reverse {
  //display: flex;
  align-items: flex-start;
}

.media--reverse > .media-object {
  order: 1
}

/**==== visuals
==*/

.media-body > p {
  min-height: 32px;
}

.media-body {
  flex: 1;
  //padding: 0 10px;
}

.media-footer {
  flex: 1;
}

.media,
.media--reverse {
  position: relative;
  //display: flex;
  align-items: flex-start;
  border: 1px solid #f2f2f2;
  padding: 16px;
  flex-basis: 30em;
  background: rgba(255,255,255,0.7);
  &.no-border {
    background:transparent;
    border:none;
  }
  .btn.btn-flat.active, .btn.btn-flat:active {
    outline: 0;
    background-image: none;
    box-shadow: none;
    color: #333;
    text-decoration: none;
  }
}

.input-group.comment-box {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  input {
    margin: 8px 0;
  }
}

/*MEDIAS*/

.media {
  margin-top: 0px;
  .author-toolbar {
    position: absolute;
    top: 0;
    right: 10px;
    // opacity: 0;
    transition: all linear 0.3s;
  }
  &:hover {
    .author-toolbar {
      opacity: 1;
    }
  }

  .media-heading {
    margin-top: 5px;
  }

  .form-group  {
    width: 100%;
  }

  .media-container {
    margin-bottom: 5px!important;
    
    @media screen and (max-width: $screen-xs-max) {
      background-color: $gray-light;
    }
    
    .video-container {
      position: relative;
      padding-bottom: 56.25%; /*16:9*/
      padding-top: 30px;
      height: 0;
      overflow: hidden;
    }

    .video-container iframe,
    .video-container object,
    .video-container embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    
    .coverImage {
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: contain;
      margin: 0 auto;
      max-height: 520px;
      max-width: 1000px;
      min-height: 330px;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .social-tools {
    
    .btn {
      padding-top: 2px;
    }
  }
  
  .collapse {
    width: 100%;
  }


}

/*hack for vimeo iframe*/
.player .vp-player-layout {
  top: 0 !important;
}

/*lightbox*/
$maxWidthContent: 900px;

.angular-lightbox {
  transition: all linear 0.5s;
  overflow:hidden;
  &.hidden {
    display: none;
  }

  section {
    position: fixed;
    z-index: 100065;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.80);

    > article {
      color: white;

      > header {
        a.close-btn {
          position: absolute;
          top: 20px;
          right: 30px;
          font-size: 3em;
          cursor: pointer;
          color: #DDD;

          &:hover, &:focus {
            color: white;
          }
        }
      }

      > content {
        margin: 0 auto;
        height: 50%;
        position: absolute;
        top: 30%;
        text-align: center;
        width: 100%;

        > iframe {
          max-width: $maxWidthContent;
          margin: 0 auto;
        }

        /*  @media screen and (min-width: $maxWidthContent) {
           width: $maxWidthContent;
         } */
      }
    }
  }
}

.wall {
  .modal-dialog {
    .modal-body {
      padding: 0 10px 10px 10px;
      height: 100%;
      .lightbox-image-caption {
        font-size: .8em;
        top:auto;
        bottom: 0;

      }
    }
  }
  .modal-backdrop {
    background-color: #ee5767;
  }

  .tooltip-inner {
    max-width:none;
  }

  .like-profiles {
    display: inline-flex;
    flex-flow: row wrap;
    background-color: #ee5767;
    color: #f2f2f2;
    a {
      margin-right: 5px;
    }
    img {
      display: block;
      width: 40px;
      height: 40px;
      margin: 0 5px 0 0;
    }
  }
}


