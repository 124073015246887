.sw-overlay {
    position: relative;

    &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        &--dark { background-color: rgba(0, 0, 0, .25); }
        &--light { background-color: rgba(255, 255, 255, .25); }
    }
}
