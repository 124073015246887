.field-credit-card {
    &__container {
        max-width: 30rem;
        margin: auto;
    }

    &__card {
        max-width: 24rem;
        margin: auto;
        margin-bottom: 1rem;
    }

    &__field {
        position: relative;
        padding: 1rem 2rem;
        border-radius: 3px;
        background: #fff;
        box-shadow: 0 .5rem 1.5rem rgba(0,0,0,.1);
    }

    &__error {
        color: red;
        text-align: center;
        padding: 1rem 2rem;
    }
}
