.deckgrid[deckgrid]::before {
    /* Specifies that the grid should have a maximum of 4 columns. Each column will have the classes 'column' and 'column-1-4' */
    content: '2 .col-xs-10.col-sm-5';
    font-size: 0; /* See https://github.com/akoenig/angular-deckgrid/issues/14#issuecomment-35728861 */
    visibility: hidden;
}

.notifications__date {
    font-size: .86em;
    color: #aaa;
}

.panel-body {
    position: relative;
    padding: 15px;
}

.notif-user-avatar {
    position: absolute;
    /*left: 25px;
    top: 25px;*/
    width: 40px;
    height: 40px;
    border-radius: 40px;
  }

.notif-text {
    padding-left: 50px;
}

.notif-action-btn {
    float:right;
}