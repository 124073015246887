.sw-gradient-button {
    display: inline-block;
    vertical-align: middle;
    line-height: 1.8;
    padding: 0 .66em;

    border: none;
    border-radius: 3px;
    background: $sw-gradient-primary;
    box-shadow: 0 .5rem 2rem rgba(0, 0, 0, 0.25);

    font-family: $font-family-sans-serif;
    font-size: 12px;
    cursor: pointer;

    &, &:hover, &:active, &:focus {
        color: rgba(255,255,255,.75);
        text-decoration: none;
    }

    &:hover {
        color: #fff;
        background: linear-gradient(90deg, lighten($sw-gradient-primary-from, 5%), lighten($sw-gradient-primary-to, 5%));
    }
    &:active {
        color: rgba(255,255,255,.75);
        background: linear-gradient(90deg, darken($sw-gradient-primary-from, 10%), darken($sw-gradient-primary-to, 10%));
    }
}
