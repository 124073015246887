//
// Popovers
// --------------------------------------------------


.popover {
  font-size: $font-size-small;

  @include box-shadow(0 1px 2px rgba(0,0,0,.10));
}

.popover-title {
  color: $popover-title-color;
  font-size: $font-size-small;
  font-weight: bold;
  text-align: center;
  padding: 6px 10px;
}

.popover-content {
  color: $popover-content-color;
  padding: 6px 10px;
}
